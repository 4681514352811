import axios from "axios";
import Auth from "components/auth/Auth";

class ContactService {
  async create(conatct) {
    try {
      const response = await axios.post(
        `${Auth.getBaseURL()}/Consumers/CreateWithAutoValidation`,
        conatct
      );
      console.log(response);
      return { IsError: false, data: response.data, status: response.status };
    } catch (error) {
      console.log("Error Creating a contact", error);
      return { IsError: true, data: "", status: error.response.status };
    }
  }

  async retrieve(index, size) {
    try {
      const response = await axios.get(
        `${Auth.getBaseURL()}/Consumers?index=${index}&size=${size}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log("Error retriving a contact", error);
      let messages = [];
      //   Object.keys(error.response.data.errors).forEach((item) => {
      //     messages.push(error.response.data.errors[item][0]);
      //   });
      console.log(messages.join(","));
    }
  }
}

export default new ContactService();
