import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";
import React, { Component } from "react";
import ErrorParser from "components/common/Parser/ErrorParser";

class ProgressBarService extends Component {
  async create(data) {
    try {
      const response = await axios.post(
        `${Auth.getBaseURL()}/ProgressBar`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async retrieve() {
    try {
      const response = await axios.get(`${Auth.getBaseURL()}/ProgressBar/`);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async retrieveProgressDatabyID(progressDataId) {
    try {
      const response = await axios.get(
        `${Auth.getBaseURL()}/ProgressBar/${progressDataId}`
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async edit(id, data) {
    try {
      const response = await axios.put(
        `${Auth.getBaseURL()}/ProgressBar/${id}`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        `${Auth.getBaseURL()}/ProgressBar/${id}`
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      const errors = ErrorParser.parseErrorMessage(error);
      return HttpResponseHandler.returnError(error.response);
    }
  }
}

export default new ProgressBarService();
