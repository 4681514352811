import { PROCESS } from "redux/processConstants";
import {
  CREATE_MARKETDATA,
  RETRIEVE_MARKETDATA,
  RETRIEVE_BY_ID_MARKETDATA,
  UPDATE_MARKETDATA,
  DELETE_MARKETDATA,
} from "./MarketdataActionType";

const initialState = {
  MarketdataListItem: [],
  MarketdataCreateItem: {},
  MarketdataGetByIdItem: 0,
  MarketdataUpdateItem: {},
  MarketdataDeleteItem: false,
  process: "",
};

const marketdataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MARKETDATA:
      console.log("Reducer Call CREATE_MARKETDATA");
      return {
        ...state,
        MarketdataCreateItem: action.payload,
        process: PROCESS.CREATE,
      };

    case RETRIEVE_MARKETDATA:
      console.log("Reducer Call RETRIEVE_MARKETDATA");
      return {
        ...state,
        MarketdataListItem: action.payload,
        process: PROCESS.RETRIEVE,
      };

    case RETRIEVE_BY_ID_MARKETDATA:
      console.log("Reducer Call RETRIEVE_BY_ID_MARKETDATA");
      return {
        ...state,
        MarketdataGetByIdItem: action.payload,
        process: PROCESS.RETRIEVE_BY_ID,
      };

    case UPDATE_MARKETDATA:
      console.log("Reducer Call UPDATE_MARKETDATA");
      return {
        ...state,
        MarketdataUpdateItem: action.payload,
        process: PROCESS.UPDATE,
      };

    case DELETE_MARKETDATA:
      console.log("Reducer Call DELETE_MARKETDATA");
      return {
        ...state,
        MarketdataDeleteItem: action.payload,
        process: PROCESS.DELETE,
      };

    default:
      return state;
  }
};

export default marketdataReducer;
