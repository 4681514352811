import React from "react";

export const AddLinkControl = ({
  onLinkTextChange,
  value,
  onLinkKeyDown,
  linkRef,
  onLinkConfirm,
}) => {
  return (
    <div>
      <input
        type="text"
        onChange={onLinkTextChange}
        value={value}
        onKeyDown={onLinkKeyDown}
        ref={linkRef}
        className="editor-link-input"
      />
      <button className="editor-link-control" onMouseDown={onLinkConfirm}>
        Confirm
      </button>
    </div>
  );
};
