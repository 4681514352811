import { Link } from "./Link";
import { findLinkEntities } from "./FindLinkEntities";
import { CompositeDecorator } from "draft-js";

export const Decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);
