import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImagePagination from "./ImagePagination";

const ImageTable = ({ galleryItems, DeleteImage, onPageNumberClick }) => {
  const [copied, setCopied] = useState(false);

  const closeModel = () => {
    console.log("Model Close Button");
    setCopied(false);
  };

  return (
    <div className="container-fluid mt-5">
      {/* {copied ? alert("Copied") : null} */}

      {copied ? (
        <div className="container d-flex justify-content-center">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 class="modal-title">Modal Header</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={(e) => closeModel(e.target.value)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Image link copied.</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row border bg-secondary d-flex justify-content-center">
        {/* <h5>{copied ? <span>Copied.</span> : null}</h5> */}
      </div>

      <div className="flex-direction: row mt-3 p-3">
        {galleryItems.map((item, index) => {
          const { id, key, url, name, fileName } = item;

          return (
            <>
              <div className="col-2 border m-4 p-2">
                <div className="pl-1">{name}</div>
                <div className="">
                  <img width={"245px"} height={"240px"} src={url} />
                </div>

                <div className="row d-flex justify-content-center pr-3 pl-1">
                  {/* <div className="col">{name}</div> */}

                  <div className="">
                    {/* ----------------(Copy Link)---------------- */}
                    <CopyToClipboard
                      text={url}
                      onCopy={() => setCopied({ copied: true })}
                    >
                      <button
                        type="button"
                        className="btn mx-1"
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                        }}
                        title="Copy Link"
                      >
                        <i class="bi bi-link-45deg"></i>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-link-45deg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                        </svg>
                      </button>
                    </CopyToClipboard>

                    {/* ------------------(Delete)---------------- */}
                    <button
                      type="button"
                      className="btn mx-1"
                      onClick={(e) => DeleteImage(id)}
                      title="Delete"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <ImagePagination imagePageNumberChange={onPageNumberClick} />
    </div>
  );
};

export default ImageTable;
