import { Editor, EditorState, convertFromRaw } from "draft-js";
import React from "react";
import { CustomStyle } from "./CustomStyle";
import { Decorator } from "./Decorator";

const ViewEditor = ({ dataToLoad }) => {
  // console.log("Rendering");
  return (
    <div className="editor-font view-editor tbnc-content">
      <Editor
        readOnly={true}
        editorState={EditorState.createWithContent(
          convertFromRaw(dataToLoad),
          Decorator
        )}
        customStyleMap={CustomStyle}
      />
    </div>
  );
};

export default ViewEditor;
