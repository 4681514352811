import React, { Component } from "react";

import FileUpload from "components/file_upload/FileUpload";

export default class NewsLetterHTMLUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilter: "",
      makeIndex: false,
    };
  }

  dateFilterChange = (e) => {
    console.log("Date selected", e.target.value);
    this.setState({ dateFilter: e.target.value });
  };

  onMakeIndexChange = (e) => {
    console.log("Is index", e.target.checked);
    this.setState({ makeIndex: e.target.checked });
  };

  render() {
    return (
      <div className="mt-5">
        <div className="border my-5">
          <div className="m-3">
          <div className="p-2">
            <label>Publish Date</label>
            <input
              type="date"
              className="ml-3"
              name="publishDate"
              value={this.state.dateFilter}
              onChange={this.dateFilterChange}
            />
            </div>
            <div className="col-sm-2 p-2">
              <input
                type="checkbox"
                name="makeIndex"
                onChange={this.onMakeIndexChange}
              />{" "}
              <span>Update as Today's edition</span>
            </div>
            <div className="col-sm-8 p-2">
              <FileUpload fileUploadType="dailynewsletter" newsletterDate={this.state.dateFilter} makeThisIndex={this.state.makeIndex} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
