import Auth from "components/auth/Auth";
import Helper from "components/common/Helper";
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import NewsLetterService from "./NewsLetterService";
import NewsLetterTable from "./NewsLetterTable";
import ScheduleNewsLetter from "./ScheduleNewsLetter";
import SendTestEmail from "./SendTestEmail";
import moment from "moment";
import DeletePrompt from "components/common/DeletePrompt";

const USER_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm";

export default class NewsLetterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilter: "",
      NewsLetters: [],
      isModalOpen: false,
      emails: "",
      ModalOpenForId: -1,
      isNewsLetterModalOpen: false,
      scheduleOn: "",
      responseMessage: "",
      userConsent: "",
      alertVariant: "success",
      showDeletePrompt: false,
      enableTrackingForTestEmail: false,
    };
  }

  componentDidMount() {
    let today = Helper.getDate();
    this.setState({ dateFilter: today });
    this.refreshNewsLetter(today);
    this.setState({ emails: `${Auth.USER_EMAIL};` });
  }

  refreshNewsLetter = (selectedDate) => {
    NewsLetterService.retrieve(selectedDate, (error, result) => {
      if (error) {
        console.log("error while getting newsletters");
      } else {
        this.setState({ NewsLetters: result });
      }
    });
  };

  createNewsLetter = () => {
    this.props.history.push(`/newsletter/create`);
  };

  uploadNewsletterContent = (event) => {
    this.props.history.push("/newsletter/daily-update");
  };

  editNewsLetter = (id, event) => {
    this.props.history.push(`/newsletter/edit/${id}`);
  };

  viewNewsLetter = (id, event) => {
    NewsLetterService.getGeneratedNewsletter(id, (error, data) => {
      if (error) {
        console.log("There was error while downloading content");
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${new Date().toJSON().slice(0, 10)}.html`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  /** Test email modal  */
  sendTestEmail = (id, event) => {
    this.setState({ isModalOpen: true, ModalOpenForId: id });
  };

  onModalInputChange = (event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [key]: val });
  };

  onModelChangesSave = () => {
    // TODO: Call the API to send the email with these data.
    NewsLetterService.sendTestEmail(
      this.state.ModalOpenForId,
      this.state.emails,
      this.state.enableTrackingForTestEmail,
      (error, result) => {
        if (error) {
          console.log("error while getting newsletters");
        } else {
          console.log("Email sent successfully!");
          this.setState({
            responseMessage: "Email sent successfully",
            alertVariant: "success",
          });
          this.hideModal();
        }
      }
    );
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
    this.hideCleanup();
  };

  uploadNewsletterImages = (id, event) => {
    this.props.history.push(`/newsletter/upload/${id}`);
  };

  /** Schedule email modal  */
  scheduleEmail = (id, event) => {
    this.setState({
      isNewsLetterModalOpen: true,
      ModalOpenForId: id,
      userConsent: "",
    });
  };

  onNewsLetterHide = (id, event) => {
    this.setState({ isNewsLetterModalOpen: false, userConsent: "" });
    this.hideCleanup();
  };

  onNewsLetterSave = (id, event) => {
    if (this.state.userConsent !== "Schedule it") {
      this.setState({
        responseMessage:
          "Error: You  must type 'Schedule it'(Without quotes and case sensitive) in input to schedule the email",
        isNewsLetterModalOpen: false,
        userConsent: "",
        alertVariant: "danger",
      });
      this.hideModal();
      return;
    }

    console.log(
      "Modal saved Newsletter",
      this.state.ModalOpenForId,
      this.state.scheduleOn
    );
    const scheduleOn = Math.round(
      moment(this.state.scheduleOn, USER_DATE_TIME_FORMAT, true).valueOf() /
        1000
    );
    console.log(scheduleOn);

    NewsLetterService.scheduleEmail(
      this.state.ModalOpenForId,
      scheduleOn,
      (error, result) => {
        if (error) {
          console.log("error while Scheduling email");
          this.setState({
            responseMessage: result,
            isNewsLetterModalOpen: false,
            userConsent: "",
            alertVariant: "danger",
          });
          this.hideModal();
        } else {
          console.log("Scheduled email!");
          this.setState({
            responseMessage: "Scheduled email successfully",
            isNewsLetterModalOpen: false,
            userConsent: "",
            alertVariant: "success",
          });
          this.removeAlert(1000);
          this.hideModal();
          this.refreshNewsLetter(this.state.dateFilter);
        }
      }
    );
  };

  // Cancel Email Schedule
  deleteNewsletterSchedule = (id) => {
    this.setState({
      showDeletePrompt: true,
      ModalOpenForId: id,
    });
  };

  onDeleteNLScheduleCancel = () => {
    this.setState({
      showDeletePrompt: false,
    });
  };

  onDeleteProgressDataConfirm = async (id) => {
    const { ModalOpenForId } = this.state;
    const apiResponse = await NewsLetterService.cancelSchedule(ModalOpenForId);
    this.setState({
      showDeletePrompt: false,
      ModalOpenForId: id,
    });
    this.refreshNewsLetter(this.state.dateFilter);
  };

  removeAlert = () => {
    setTimeout(() => {
      this.setState({ responseMessage: "" });
    }, 1000);
  };

  hideCleanup = () => {
    this.setState({
      ModalOpenForId: -1,
      userConsent: "",
      enableTrackingForTestEmail: false,
    });
  };

  dateFilterChange = (e) => {
    // console.log("Date selected", e.target.value);
    this.setState({ dateFilter: e.target.value });
    this.refreshNewsLetter(e.target.value);
  };

  render() {
    return (
      <div className="my-5">
        <div className="row">
          <div className="col-sm-12 my-2">
            {this.state.responseMessage.length > 0 ? (
              <Alert variant={this.state.alertVariant}>
                {this.state.responseMessage}
              </Alert>
            ) : null}
          </div>
          {/* --------------------------------------------- */}
          <div className="">
            <DeletePrompt
              show={this.state.showDeletePrompt}
              header="Delete Newsletter Schedule"
              body="Are You Sure Want to Delete this Newsletter Schedule"
              onHide={this.onDeleteNLScheduleCancel}
              onSuccess={this.onDeleteProgressDataConfirm}
            />
          </div>
          {/* ------------------------------------------------- */}
          <div className="col-sm-12 my-2">
            <h4>Newsletters</h4>
          </div>
          <div className="col-sm-12 my-2">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={this.uploadNewsletterContent}
              >
                Upload HTML
              </button>

              <button
                type="button"
                className="btn btn-success"
                onClick={this.createNewsLetter}
              >
                Create Newsletter
              </button>
            </div>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-sm-3">
            <label htmlFor="dateFilter" className="mr-3">
              From
            </label>
            <input
              type="date"
              name="dateFilter"
              value={this.state.dateFilter}
              onChange={this.dateFilterChange}
            />
          </div>
          <div className="col-sm-3"></div>
          <div className="col-sm-3"></div>
          <div className="col-sm-3"></div>
        </div>

        <NewsLetterTable
          newsLetters={this.state.NewsLetters}
          viewNewsLetter={this.viewNewsLetter}
          editNewsLetter={this.editNewsLetter}
          sendTestEmail={this.sendTestEmail}
          scheduleEmail={this.scheduleEmail}
          uploadNewsletterImages={this.uploadNewsletterImages}
          deletePrompt={this.deleteNewsletterSchedule}
        />

        <SendTestEmail
          show={this.state.isModalOpen}
          header={"Send Test emails"}
          textareaValue={this.state.emails}
          onHide={this.hideModal}
          onSuccess={this.onModelChangesSave}
          textareaOnChange={this.onModalInputChange}
          enableTrackingForTestEmail={this.state.enableTrackingForTestEmail}
        />

        <ScheduleNewsLetter
          show={this.state.isNewsLetterModalOpen}
          header={"Schedule Newsletter"}
          scheduleOnValue={this.state.scheduleOn}
          userConsent={this.state.userConsent}
          onHide={this.onNewsLetterHide}
          onSuccess={this.onNewsLetterSave}
          scheduleOnChange={this.onModalInputChange}
        />
      </div>
    );
  }
}
