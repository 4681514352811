import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";
import ErrorParser from "components/common/Parser/ErrorParser";

class MarketDataService {
  async create(data) {
    try {
      console.log("Market Data Post to Server", data);
      const response = await axios.post(
        `${Auth.getBaseURL()}/MarketDataSet`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Service Post Error => Create Tiker data", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async retreive(publishDate) {
    try {
      console.log("Market Data Retrieve from server", publishDate);
      const response = await axios.get(
        `${Auth.getBaseURL()}/MarketDataSet?date=${publishDate}`
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Service Retrieve Error => Retreive Tiker data", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async retrivemarketDataById(marketdataId) {
    try {
      const response = await axios.get(
        `${Auth.getBaseURL()}/MarketDataSet/${marketdataId}`
      );
      // console.log("Service = Market data retrieve by ID", marketdataId);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Service Retrieve Error => Retreive Tiker data", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async edit(id, data) {
    try {
      const response = await axios.put(
        `${Auth.getBaseURL()}/MarketDataSet/${id}`,
        data
      );
      // console.log("Data PUT/UPDATE to Server", response);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Service Edit Error => Edit Tiker data", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        `${Auth.getBaseURL()}/MarketDataset/${id}`
      );
      // console.log("Delete Market Data", response);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Service Delete Error => Delete Tiker data", error);
      const errors = ErrorParser.parseErrorMessage(error, "Delete MarketData");
      return HttpResponseHandler.returnError(error.response);
    }
  }
}
export default new MarketDataService();
