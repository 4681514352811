import {
  EDITOR_FONT_WEIGHT,
  EDITOR_TEAL,
} from "components/editor/DraftClassNameConstants";

export const CustomStyle = {
  TEALBOLD: {
    color: EDITOR_TEAL,
    fontWeight: EDITOR_FONT_WEIGHT,
  },
};
