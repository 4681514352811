import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import moment from 'moment';
import React, { Component } from "react";

const USER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

class ScheduleNewsLetter extends Component {
  render() {
    const {
      show,
      onHide,
      header,
      scheduleOnChange,
      onSuccess,
      scheduleOnValue,
      userConsent
    } = this.props;

    const scheduleReadableFormat = scheduleOnValue && scheduleOnValue.length > 0 ?  moment(scheduleOnValue,USER_DATE_TIME_FORMAT, true).fromNow() : '';
    const scheduleUnix = scheduleOnValue && scheduleOnValue.length > 0 ?  Math.round(moment(scheduleOnValue,USER_DATE_TIME_FORMAT, true).valueOf()/1000) : '';
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
          type="datetime-local"
            onChange={(e) => scheduleOnChange(e)}
            value={scheduleOnValue}
            name="scheduleOn"/>


          <p className="text-danger mt-5">
            Type in the below input box <strong>Schedule it</strong>. Typing is user consent that the newsletter is ready to go.  
          </p>
          <input
          type="text"
            onChange={(e) => scheduleOnChange(e)}
            value={userConsent}
            className="w-75"
            placeholder="Type user consent text => Schedule it"
            name="userConsent"/>


          <p className="text-danger mt-5">
            <strong>Make sure you've done all proof readings and this process cannot be reversed!</strong> 
          </p>
          <p>
            {scheduleUnix}
          </p>
          <p className="text-success">
            
            Your email will be sent in <strong>{scheduleReadableFormat}</strong> 
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSuccess}>
            Schedule Email
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ScheduleNewsLetter.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  scheduleOnValue: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  scheduleOnChange: PropTypes.func.isRequired,
};

export default ScheduleNewsLetter;
