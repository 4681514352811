import React from "react";
import { AddLinkControl } from "./AddLinkControl";

const CustomLink = ({
  onLinkAddClick,
  onLinkRemove,
  showURL,
  onLinkTextChange,
  value,
  onLinkKeyDown,
  linkRef,
  onLinkConfirm,
}) => {
  return (
    <>
      <div>
        <button
          className="editor-link-control"
          type="button"
          onMouseDown={onLinkAddClick}
        >
          Add link
        </button>
        <button
          className="editor-link-control"
          type="button"
          onMouseDown={onLinkRemove}
        >
          Remove link
        </button>
      </div>
      {showURL ? (
        <AddLinkControl
          onLinkTextChange={onLinkTextChange}
          value={value}
          onLinkKeyDown={onLinkKeyDown}
          linkRef={linkRef}
          onLinkConfirm={onLinkConfirm}
        />
      ) : null}
    </>
  );
};

export default CustomLink;
