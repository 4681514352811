import { PageMask } from "components/common/PageMask";
import DraftEditor from "components/editor/DraftEditor";
import React, { useState } from "react";
import SendEmailService from "./SendEmailService";

const SendEmailToUser = () => {
  const [emailHTML, setEmailHTML] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactEmails, setContactEmails] = useState("");
  const [pageName, setpageName] = useState(
    "Send email to user(s) - Contacts only"
  );
  const [subject, setSubject] = useState("");
  const logState = (json, html) => {
    setEmailHTML(html);
  };

  const sendEmailToContacts = async () => {
    console.log("Sending email to contacts");

    const consumerIds = contactEmails.split(";").map((item) => {
      return parseInt(item, 10);
    });

    // set the email details.
    const emailDetails = {
      ConsumerIds: consumerIds,
      Content: emailHTML,
      Subject: subject,
    };
    console.log("Sending email to contact", emailDetails);
    // set the loading option to true.
    setIsLoading(true);

    // Call the API.
    const apiResponse = await SendEmailService.sendEmailToContatcts(
      emailDetails
    );
    console.log("Send email to contacts api response", apiResponse);

    // Set the loading to false.
    setIsLoading(false);
  };

  const sendTestEmailToContacts = async () => {
    console.log("Sending test email to anyone");

    const emailDetails = {
      Subject: subject,
      Content: emailHTML,
      Addresses: contactEmails,
    };

    setIsLoading(true);

    const apiResponse = await SendEmailService.sendTestEmail(emailDetails);
    console.log("Send test email api response", apiResponse);

    setIsLoading(false);
    console.log(emailHTML, contactEmails, subject);
  };

  return (
    <div className="mt-5">
      {isLoading && <PageMask />}
      {/* <DraftEditor save={logState} /> */}
      <div className="">
        <div className="col-sm-12 border p-5">
          <h3>{pageName}</h3>
          <div className="mt-5">
            {errorMessage}

            <form>
              <div className="form-group mb-3">
                <label htmlFor="contactEmails">Contact emails</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setContactEmails(e.target.value)}
                  placeholder="For test email enter emails, for actual emails enter consumer ids. separator - semicolon(;)"
                  value={contactEmails}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="subject">Email subject</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter subject of email"
                  value={subject}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="imageBy">Email content</label>
                <div className="bg-light p-5 border-light rounded-corner">
                  <DraftEditor save={logState} />
                </div>
              </div>

              <div className="form-group mb-3 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={sendTestEmailToContacts}
                >
                  Send test email
                </button>
                <button
                  className="btn btn-danger  ml-3"
                  type="button"
                  onClick={sendEmailToContacts}
                >
                  Send email
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmailToUser;
