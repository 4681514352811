import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ImageService from "./ImageService";
import ImageTable from "./ImageTable";
import DeletePrompt from "components/common/DeletePrompt";

const ImageGrid = () => {
  const history = useHistory();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [items, setItems] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [imageId, setImageId] = useState(-1);

  useEffect(() => {
    console.log("UseEffect Call");
    retrieve();
  }, [pageIndex]);

  const retrieve = async () => {
    console.log("Retrieve Image Data From Server");
    const { data, success } = await ImageService.retrieve(pageIndex, pageSize);
    setItems(data);
    console.log("Retrieve API Data ", data);
  };

  const selectImage = () => {
    console.log("Go to for Attach Image from Folder");
    history.push(`/gallery/upload`);
  };

  const deleteImage = async (id) => {
    console.log("Delete Image Function Call", id);
    setShowDeletePrompt(true);
    setImageId(id);

    retrieve();
  };

  const onDeleteImageCancel = () => {
    console.log("Delete Canced ");
    setShowDeletePrompt(false);
  };

  const onDeleteImageConfirm = async (id) => {
    const apiResponse = await ImageService.deleteImage(imageId);
    retrieve();
    setShowDeletePrompt(false);
    setImageId(-1);
  };

  const handlePageClick = (data) => {
    console.log("object");
    let selected = data.selected;
    setPageIndex(selected);
  };

  return (
    <div>
      <DeletePrompt
        show={showDeletePrompt}
        header="Delete Image"
        body="Are You Sure Want to Delete this Image"
        onHide={onDeleteImageCancel}
        onSuccess={onDeleteImageConfirm}
      />

      <div className="container-border m-3">
        <div className="row">
          <div className="col-3">
            <h1>Image Library</h1>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={selectImage}
            >
              Upload Image
            </button>
          </div>
        </div>

        <ImageTable
          galleryItems={items}
          DeleteImage={deleteImage}
          onPageNumberClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export default ImageGrid;
