import React from "react";
import ReactPaginate from "react-paginate";

const ImagePagination = ({ imagePageNumberChange }) => {
  return (
    <div>
      <div className="contactpagelist">
        <ReactPaginate
          previousPage={"previous"}
          nextPage={"next"}
          breakLabel={". . ."}
          breakClassName={"break-me page-numbers"}
          pageCount={1000}
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={imagePageNumberChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default ImagePagination;
