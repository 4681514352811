import { Button } from "react-bootstrap";
import React from "react";
import { Modal } from "react-bootstrap";
import NewEditor from "./NewEditor";

function ModalEditor(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rich text editor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewEditor onSave={props.onSave} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onSave}>Save</Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditor;
