import CategoryService from "components/category/CategoryService";
import DeletePrompt from "components/common/DeletePrompt";
import Helper from "components/common/Helper";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RoundupService from "./RoundupService";
import RoundupTable from "./RoundupTable";

const RoundupList = () => {
  const history = useHistory();

  const [roundups, setRoundups] = useState([]);
  const [dateFilter, setDateFilter] = useState(Helper.getDate());
  const [categories, setCategories] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [roundupID, setRoundupID] = useState(-1);

  useEffect(() => {
    retrieve();
  }, [dateFilter]);

  const retrieve = async () => {
    // Get Categories also.
    const categoryList = await fillCategories();
    console.log("Category filled");

    const data = await RoundupService.retrieve(dateFilter);
    console.log("Got roundups");
    const updatedRounpWithCategory = data.map((item) => {
      let categoryName = getCategoryName(item.categoryId, categoryList);
      return { ...item, CategoryName: categoryName };
    });

    setRoundups(updatedRounpWithCategory);
    console.log("retrieve roundup", data, updatedRounpWithCategory);
  };

  const getCategoryName = (id, categoryList) => {
    for (let index = 0; index < categoryList.length; index++) {
      if (categoryList[index]["id"] === id) {
        return categoryList[index]["name"];
      }
    }
    return "Unknown";
  };

  const fillCategories = async () => {
    const { data } = await CategoryService.retrieve();
    setCategories(data);
    return data;
  };

  const createRoundup = () => {
    history.push(`/roundup/create`);
  };

  const editRoundup = (event, id) => {
    history.push(`/roundup/edit/${id}`);
  };

  const onDeleteRoundupCancel = () => {
    setShowDeletePrompt(false);
  };

  const deleteRoundup = (event, id) => {
    console.log("Deleting id of roundup", id);
    setShowDeletePrompt(true);
    setRoundupID(id);
  };

  // =============delete ===========//

  const deleteRoundupconfirm = async () => {
    console.log("Deleting id of roundup", roundupID);
    const apiResponse = await RoundupService.delete(roundupID);
    console.log("got response from API delete roundup", apiResponse);
    // Retirve
    retrieve();
    setShowDeletePrompt(false);
    setRoundupID(-1);
  };

  // =============delete ===========//

  return (
    <div className="my-5">
      <DeletePrompt
        show={showDeletePrompt}
        header="Delete Roundup"
        body="Are you Sure want to delete this roundup"
        onHide={onDeleteRoundupCancel}
        onSuccess={deleteRoundupconfirm}
      />

      <div className="row">
        <div className="col-sm-12 my-2">
          <h4>Roundups List</h4>
        </div>
        <div className="col-sm-12">
          <div className="col-sm-3">
            <label htmlFor="dateFilter" className="mr-3">
              Date Filter
            </label>
            <input
              type="date"
              name="dateFilter"
              value={dateFilter}
              onChange={(event) => setDateFilter(event.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-12 my-2">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={createRoundup}
            >
              Create Roundup
            </button>
          </div>
        </div>
      </div>
      <RoundupTable
        roundupList={roundups}
        editRoundup={editRoundup}
        deleteRoundup={deleteRoundup}
      />
    </div>
  );
};

export default RoundupList;
