import CategoryService from "components/category/CategoryService";
import {
  CREATE_CATEGORY,
  RETRIEVE_CATEGORY,
  RETRIEVE_BY_ID_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./CategoryActionType";

// ================================================================

export const createCategory = (apiData) => async (dispach, getAction) => {
  try {
    console.log("Action=> create Category Request", apiData);
    const { data, success } = await CategoryService.create(apiData);
    console.log("Action=> create Category Response", data);
    dispach({ type: CREATE_CATEGORY, payload: data });
  } catch (error) {
    console.log("Action=> create Category Error Response", error);
  }
};

// -----------------------------------------------------------------

export const retrieveCategory = () => async (dispach, getAction) => {
  try {
    console.log("Action=> Retrieve Category Request");
    const { data, success } = await CategoryService.retrieve();
    console.log("Action=> Retrieve Category API Response", data);
    dispach({ type: RETRIEVE_CATEGORY, payload: data });
  } catch (error) {
    console.log("Action=> Retrieve Category Error Response", error);
  }
};

// -----------------------------------------------------------------

export const getByIdCategory = (id) => async (dispach, getAction) => {
  try {
    console.log("Action=> getByIdCategory Category Request", id);
    const { data, success } = await CategoryService.getCategoryByID(id);
    console.log("Action=> getByIdCategory Category API Response", data);
    dispach({ type: RETRIEVE_BY_ID_CATEGORY, payload: data });
  } catch (error) {
    console.log("Action=> getByIdCategory Category Error Response", error);
  }
};

// -----------------------------------------------------------------

export const updateCategory =
  (apiData, categoryID, id) => async (dispach, getAction) => {
    try {
      console.log("Actions Call Edit Category Request", apiData, categoryID);
      const { data, success } = await CategoryService.edit(categoryID, apiData);
      console.log("Action Call Edit Category API Response", data);
      dispach({ type: UPDATE_CATEGORY, payload: data });
    } catch (error) {
      console.log("Action=> Edit Category Error Response", error);
    }
  };

// -----------------------------------------------------------------

export const deletecategory = (id) => async (dispach, getAction) => {
  try {
    console.log("Action=> Delete Category Id Request", id);
    const { data, success } = await CategoryService.deleteCategory(id);
    dispach({ type: DELETE_CATEGORY, payload: { id } });
    console.log("Action=> Delete Category API Response", success);
  } catch (error) {
    console.log("Action=> Delete Category Error Response", error);
  }
};

// -----------------------------------------------------------------
