import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

import React, { Component } from "react";

class SendTestEmail extends Component {
  render() {
    const {
      show,
      onHide,
      header,
      textareaOnChange,
      onSuccess,
      textareaValue,
      enableTrackingForTestEmail,
    } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <textarea
            onChange={(e) => textareaOnChange(e)}
            value={textareaValue}
            name="emails"
            rows="3"
            className="full-width"
            placeholder="semicolon separated emails e.g. akash@boringnews.co;kaushik@boringnews.co;"
          ></textarea>
          <div className="pt-2">
            <input
              className="mr-2 md-checkbox"
              type="checkbox"
              id="enableTrackingForTestEmail"
              name="enableTrackingForTestEmail"
              value={enableTrackingForTestEmail}
              onChange={(e) => textareaOnChange(e)}
            />
            <label for="enableTrackingForTestEmail">
              Enable tracking for test email
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSuccess}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

SendTestEmail.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  textareaValue: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  textareaOnChange: PropTypes.func.isRequired,
};

export default SendTestEmail;
