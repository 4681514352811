import React, { Component } from 'react'
import Auth from "components/auth/Auth";

export default class Logout extends Component {
    componentDidMount(){
        Auth.logout(() => {
            this.props.history.push("/");
        });
    }
    render() {
        return (
            <div>                
            </div>
        )
    }
}
