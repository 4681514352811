/*
 * This is a rootreducer file.
 * Import all indivisual reducer file.
 */
import { combineReducers } from "redux";
import categoryReducer from "../actions/category/CategoryReducer";
import progressbarReducer from "../actions/progressbar/ProgressbarReducer";
import marketdataReducer from "../actions/marketData/MarketdataReducer";

export const rootReducer = combineReducers({
  CAT: categoryReducer,
  PBR: progressbarReducer,
  MDR: marketdataReducer,
});
