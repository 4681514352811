/*
 * Category Common Action Type
 */

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const RETRIEVE_CATEGORY = "RETRIEVE_CATEGORY";

export const RETRIEVE_BY_ID_CATEGORY = "RETRIEVE_BY_ID_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const DELETE_CATEGORY = "DALETE_CATEGORY";
