import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from 'prop-types';


import React, { Component } from "react";


class DeletePrompt extends Component {
  render() {
    const { show, onHide, header, body, onSuccess } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onSuccess}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DeletePrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeletePrompt;
