import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  render() {
    return (
      <div className="bg-light border-right" id="sidebar-wrapper">
        <div className="sidebar-heading font-weight-bold">TBNC CMS</div>
        <div className="list-group list-group-flush">
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/"
          >
            Home
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/story" }}
          >
            Stories
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/category" }}
          >
            Categories
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/newsletter" }}
          >
            Newsletters
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/roundup"
          >
            Roundups
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/financeData" }}
          >
            Market Data
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/progressbar" }}
          >
            Progress Bars
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/gallery" }}
          >
            Gallery
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/send-email" }}
          >
            Send Email
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/contact/list" }}
          >
            Contacts
          </Link>{" "}
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/user/reset-password" }}
          >
            User
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to={{ pathname: "/logout" }}
          >
            Logout
          </Link>{" "}
          {/*  */}
        </div>
      </div>
    );
  }
}
