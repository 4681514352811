import React, { Component, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import FileUploadMessage from "./FileUploadMessage";
import FileUploadService from "./FileUploadService";

export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      fileName: "Choose file",
      statusMessage: "",
      uploadPercentage: 0,
      isLoading: false,
    };
  }
  onFileSelect = (e) => {
    const fileDetails = e.target.files[0];
    console.log("File uploaded", fileDetails.name);
    this.setState({
      file: fileDetails,
      fileName: fileDetails.name,
    });
  };
  onUpload = (e) => {
    if (this.props.fileUploadType === "newsletter") {
      this.newsLetterFileUpload();
    } else if (this.props.fileUploadType === "story") {
      this.storyFileUpload();
    }
    else if(this.props.fileUploadType === "dailynewsletter"){
      this.newsLetterHTMLUpload();
    }
  };

  storyFileUpload = () => {
    if (!(this.props.storyid > 0)) {
      this.setState({
        statusMessage: "Try re uploading the image, Story not found!",
      });
      return;
    }
    this.setState({ isLoading: true });
    console.log("uploading files");
    const formData = new FormData();
    formData.append("", this.state.file);
    FileUploadService.upload(
      this.props.storyid,
      formData,
      this.onUploadProgress,
      (error, data) => {
        if (error) {
          this.setState({
            statusMessage: data,
            isLoading: false,
          });
        } else {
          // data + Success
          this.setState({
            statusMessage: "Uploaded successfully!",
            isLoading: false,
          });
        }
      }
    );
  };

  newsLetterFileUpload = () => {
    const sectionID = this.props.newsletterSectionId;
    if (!(sectionID > 0)) {
      this.setState({
        statusMessage:
          "Try re uploading the image, Newsletter section not found!",
      });
      return;
    }
    this.setState({ isLoading: true });
    console.log("uploading files");
    const formData = new FormData();
    formData.append("", this.state.file);
    FileUploadService.uploadNewsletterImages(
      sectionID,
      formData,
      this.onUploadProgress,
      (error, data) => {
        if (error) {
          this.setState({
            statusMessage: data,
            isLoading: false,
          });
        } else {
          // data + Success
          this.setState({
            statusMessage: "Uploaded successfully!",
            isLoading: false,
          });
        }
      }
    );
  };

  newsLetterHTMLUpload = () =>{
    const { newsletterDate,makeThisIndex } = this.props;
    // uploadNewsletterHTML
    if (typeof newsletterDate === "undefined" || typeof makeThisIndex !== "boolean") {
      this.setState({
        statusMessage:
          "Select newsletter date! If issue persists contact dev team.",
      });
      return;
    }

    if(newsletterDate.length <= 0){
      this.setState({
        statusMessage:
          "Invalid date selected.",
      });
      return;
    }
    

    this.setState({ isLoading: true });
    console.log("uploading html content of newsletter");

    const formData = new FormData();
    formData.append("", this.state.file);

    FileUploadService.uploadNewsletterHTML(
      newsletterDate,makeThisIndex,
      formData,
      this.onUploadProgress,
      (error, data) => {
        if (error) {
          this.setState({
            statusMessage: data,
            isLoading: false,
          });
        } else {
          // data + Success
          this.setState({
            statusMessage: "Uploaded successfully!",
            isLoading: false,
          });
        }
      }
    );
  }

  onUploadProgress = (progressEvent) => {
    const value = parseInt(
      Math.round((progressEvent.loaded * 100) / progressEvent.total),
      10
    );
    this.setState({
      uploadPercentage: value,
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.statusMessage.length > 0 ? (
          <div className="form-row min-h-100">
            <div className="col-sm-12">
              <FileUploadMessage message={this.state.statusMessage} />
            </div>
          </div>
        ) : null}

        <div className="form-row my-3">
          <div className="col-sm-8">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={this.onFileSelect}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {this.state.fileName}
              </label>
            </div>
          </div>
          <div className="col-sm-3 d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success btn-sm"
              name="upload"
              onClick={this.onUpload}
            >
              Upload
            </button>
          </div>
          <div className="col-sm-1 d-flex align-items-center">
            {this.state.isLoading ? (
              <div className="form-row">
                <div className="col-sm-12">
                  <Spinner animation="border" variant="success" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}
