import "bootstrap/dist/css/bootstrap.min.css";
import "startbootstrap-simple-sidebar/css/simple-sidebar.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import Login from "components/auth/Login";
import Category from "components/category/Category";
import Story from "components/story/Story";
import { ProtectedRoute } from "components/auth/ProtectedRoute";
import Sidebar from "components/sidebar/sidebar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import StoryList from "components/story/StoryList";
import StoryView from "components/story/StoryView";
import Logout from "components/auth/Logout";
import CategoryList from "components/category/CategoryList";
import NewsLetter from "components/newsletter/NewsLetter";
import NewsLetterList from "components/newsletter/NewsLetterList";
import NewsLetterImageUpload from "components/newsletter/NewsLetterImageUpload";
import Demo from "components/editor/Demo";
import NewsLetterHTMLUpload from "components/newsletter/NewsLetterHTMLUpload";
import CreateContact from "components/contact/CreateContact";
import ContactList from "components/contact/ContactList";
import ResetPassword from "components/User/ResetPassword";
import RoundupList from "components/roundup/RoundupList";
import Roundup from "components/roundup/Roundup";
import SendEmailToUser from "components/email/SendEmailToUser";
import CreateStory from "components/story/NewStory/CreateStory";
import MarketList from "components/marketData/MarketList";
import CreateMarketData from "components/marketData/CreateMarketData";
import CreateProgressBar from "components/ProgressBar/CreateProgressBar";
import ProgressBarList from "components/ProgressBar/ProgressBarList";
import DragAndDrop from "components/dnd/DragAndDrop";
import SimpleInlineToolbarEditor from "components/experiment/SimpleInlineToolbarEditor";
import NewEditor from "components/common/new.editor/NewEditor";
import EditorLandingPage from "components/common/new.editor/EditorLandingPage";
import NewNewsLetter from "components/newsletter/NewNewsletter";
import ImageGrid from "components/gallery/ImageGrid";
import ImageUpload from "components/gallery/ImageUpload";

function App() {
  return (
    <Router>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <Switch>
              <Route exact path="/" component={Login}></Route>
              <Route exact path="/editor" component={Demo}></Route>
              <Route exact path="/dnd" component={DragAndDrop}></Route>
              <Route
                exact
                path="/inline-editor"
                component={EditorLandingPage}
              ></Route>

              <Route exact path="/new-editor" component={NewEditor}></Route>

              <ProtectedRoute exact path="/logout" component={Logout} />

              <ProtectedRoute exact path="/category" component={CategoryList} />
              <ProtectedRoute
                exact
                path="/category/create"
                component={Category}
              />
              <ProtectedRoute
                exact
                path="/category/edit/:id"
                component={Category}
              />

              <ProtectedRoute exact path="/story/create" component={Story} />
              <ProtectedRoute exact path="/story/edit/:id" component={Story} />
              <ProtectedRoute exact path="/story" component={StoryList} />
              <ProtectedRoute
                exact
                path="/story/view/:id"
                component={StoryView}
              />

              <ProtectedRoute
                exact
                path="/story/story-create-new"
                component={CreateStory}
              />
              <ProtectedRoute
                exact
                path="/story/story-create-new/:storyid"
                component={CreateStory}
              />

              <ProtectedRoute
                exact
                path="/newsletter"
                component={NewsLetterList}
              />
              <ProtectedRoute
                exact
                path="/newsletter/edit/:id"
                component={NewNewsLetter}
              />
              <ProtectedRoute
                exact
                path="/newsletter/create"
                component={NewNewsLetter}
              />
              <ProtectedRoute
                exact
                path="/newsletter/upload/:id"
                component={NewsLetterImageUpload}
              />

              <ProtectedRoute exact path="/roundup" component={RoundupList} />
              <ProtectedRoute
                exact
                path="/roundup/create"
                component={Roundup}
              />
              <ProtectedRoute
                exact
                path="/roundup/edit/:id"
                component={Roundup}
              />

              <ProtectedRoute
                exact
                path="/financedata"
                component={MarketList}
              />
              <ProtectedRoute
                exact
                path="/financedata/create"
                component={CreateMarketData}
              />
              <ProtectedRoute
                exact
                path="/financedata/edit/:id"
                component={CreateMarketData}
              />

              <ProtectedRoute
                exact
                path="/newsletter/daily-update"
                component={NewsLetterHTMLUpload}
              />

              <ProtectedRoute
                exact
                path="/progressbar"
                component={ProgressBarList}
              />
              <ProtectedRoute
                exact
                path="/progressbar/create"
                component={CreateProgressBar}
              />
              <ProtectedRoute
                exact
                path="/progressbar/edit/:id"
                component={CreateProgressBar}
              />

              <ProtectedRoute exact path="/gallery" component={ImageGrid} />
              <ProtectedRoute
                exact
                path="/gallery/upload/:id"
                component={ImageGrid}
              />
              <ProtectedRoute
                exact
                path="/gallery/upload"
                component={ImageUpload}
              />

              <ProtectedRoute
                exact
                path="/send-email"
                component={SendEmailToUser}
              />

              <ProtectedRoute
                exact
                path="/contact/list"
                component={ContactList}
              />
              <ProtectedRoute
                exact
                path="/contact/create"
                component={CreateContact}
              />

              <ProtectedRoute
                exact
                path="/user/reset-password"
                component={ResetPassword}
              />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
