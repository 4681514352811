import { PROCESS } from "redux/processConstants";
import {
  CREATE_CATEGORY,
  RETRIEVE_CATEGORY,
  RETRIEVE_BY_ID_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./CategoryActionType";

// =================================================================

const initialState = {
  CategoryListData: [],
  categoryCreateData: {},
  categoryGetByIdData: {},
  categoryUpdateData: {},
  categoryDeleteData: false,
  process: "",
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY:
      console.log("Reducess Call CREATE_CATEGORY");
      return {
        ...state,
        categoryCreateData: action.payload,
        process: PROCESS.CREATE,
      };

    case RETRIEVE_CATEGORY:
      console.log("Reducer Call RETRIEVE_CATEGORY");
      return {
        ...state,
        CategoryListData: action.payload,
        process: PROCESS.RETRIEVE,
      };

    case RETRIEVE_BY_ID_CATEGORY:
      console.log("Reducess Call RETRIEVE_BY_ID_CATEGORY");
      return {
        ...state,
        categoryGetByIdData: action.payload,
        process: PROCESS.RETRIEVE_BY_ID,
      };

    case UPDATE_CATEGORY:
      console.log("Reducess Call UPDATE_CATEGORY", action.payload);
      return {
        ...state,
        categoryUpdateData: action.payload,
        process: PROCESS.UPDATE,
      };

    case DELETE_CATEGORY:
      console.log("Reducess Call DELETE_CATEGORY");
      return {
        ...state,
        categoryDeleteData: action.payload,
        process: PROCESS.DELETE,
      };

    default:
      return state;
  }
};

export default categoryReducer;
