import React, { Component } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";
import CustomLink from "./CustomLink";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { CustomStyle } from "./CustomStyle";
import { Decorator } from "./Decorator";

export class NewEditor extends Component {
  // Ctor
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props.dataToLoad
        ? EditorState.createWithContent(
            convertFromRaw(this.props.dataToLoad),
            Decorator
          )
        : EditorState.createEmpty(Decorator),
      urlValue: "",
      showURLInput: false,
    };
    this.linkRef = React.createRef();
    this.editorRef = React.createRef();

    this.focus = () => this.editorRef.current.focus();
  }

  // On editor state change
  onEditorStateChange = (editorState) => this.setState({ editorState });

  // BLock type toggle
  toggleBlockType = (blockType) => {
    this.onEditorStateChange(
      RichUtils.toggleBlockType(this.state.editorState, blockType)
    );
  };

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onEditorStateChange(newState);
      return true;
    }
    return false;
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onEditorStateChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  onURLChange = (e) => this.setState({ urlValue: e.target.value });

  onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      this.confirmLink(e);
    }
  };

  confirmLink = (e) => {
    e.preventDefault();
    const { editorState, urlValue } = this.state;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlValue, class: "tbnc-email-link" }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState({
      editorState: RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      ),
      showURLInput: false,
      urlValue: "",
    });
  };

  removeLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  promptForLink = (e) => {
    console.log("Prompt link");
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = "";
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.linkRef.current.focus(), 0);
          console.log(this.state.showURLInput);
        }
      );
    }
  };

  getContentAsJson = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const jsonObj = convertToRaw(contentState);
    this.props.onSave(jsonObj);
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rich text editor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="wrapper">
              <div className="controls">
                <BlockStyleControls
                  editorState={this.state.editorState}
                  onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                  editorState={this.state.editorState}
                  onToggle={this.toggleInlineStyle}
                />
                <CustomLink
                  onLinkAddClick={this.promptForLink}
                  onLinkRemove={this.removeLink}
                  showURL={this.state.showURLInput}
                  onLinkTextChange={this.onURLChange}
                  value={this.state.urlValue}
                  onLinkKeyDown={this.onLinkInputKeyDown}
                  linkRef={this.linkRef}
                  onLinkConfirm={this.confirmLink}
                />
              </div>
              <div className="editor editor-adjust tbnc-content">
                <Editor
                  editorState={this.state.editorState}
                  onChange={this.onEditorStateChange}
                  placeholder="Enter your content here."
                  handleKeyCommand={this.handleKeyCommand}
                  customStyleMap={CustomStyle}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.getContentAsJson}>Save</Button>
          <Button onClick={() => this.props.onHide(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NewEditor;
