import CategoryService from "components/category/CategoryService";
import React, { useState, useEffect } from "react";
import RoundupService from "./RoundupService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useHistory, useParams } from "react-router-dom";
import StoryService from "components/story/StoryService";

const animatedComponents = makeAnimated();
const CreateRoundup = () => {
  const params = useParams();
  const waitTime = 4000;
  const [publishDate, setPublishDate] = useState(" ");
  const [openingtext, setOpeningText] = useState(" ");
  const [categorySelect, setCategorySelect] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(-1);
  const [isPublished, setIsPublished] = useState(false);
  const [externalURL, setExternalURL] = useState("");

  const [pageTitle, setPageTitle] = useState("Create Roundup");
  const [submitButtonName, setSubmitButtonName] = useState("Create");

  const [storySelect, setStorySelect] = useState(false);
  const [roundupStories, setRoundupStories] = useState([]);

  const [currentRoundupID, setCurrentRoundupID] = useState(-1);
  const [isEditPage, setIsEditPage] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseOK, setIsResponseOK] = useState(null);

  const [disableCategory, setDisableCategory] = useState(false);

  const history = useHistory();

  useEffect(() => {
    retrieve();
  }, []);

  /**
   * This is ready to use code, on category change.
   * But the API is not accepting the category for now.
   * So better to disable this.
   * */

  // useEffect(() => {}, [selectedCategory.value]);

  const retrieve = async () => {
    const data = await CategoryService.retrieve();
    console.log("Reterive All Category", data.data);
    let categoryData = data.data;
    if (categoryData && categoryData.length > 0) {
      const selectData = categoryData.map((item) => {
        return { value: item.id, label: item.name };
      });
      console.log("Fill Reterive All Category", selectData);
      setCategorySelect(selectData);
    }

    // EDITING the roundup page
    if (params.id > 0) {
      console.log("Roundup Edit Call");
      // Set inital edit function changes.
      setIsEditPage(true);
      setPageTitle("Edit Roundup");
      setSubmitButtonName("Update");
      setDisableCategory(true);

      // Retireve the roundup data.
      const apiResponse = await RoundupService.retrieveById(params.id);
      console.log("Populating data from roundup edit", apiResponse);

      // Extract the roundup columns
      const {
        publishDate,
        openingText,
        categoryId,
        roundupSections,
        isPublished,
        externalURL,
      } = apiResponse;

      // Set category ID
      setCategoryId(categoryId);

      // Fill the publish date.
      setPublishDate(publishDate.substring(0, 10));

      // Set Is published changes.
      setIsPublished(isPublished);

      // Fill the opening text
      setOpeningText(openingText);

      // Fill External URL
      setExternalURL(externalURL);

      // Filter the selected categories.
      const selectedCategoryFromEdit = categoryData.filter((item) => {
        return item.id === categoryId;
      });

      // Set the selected category in the form
      setSelectedCategory({
        value: selectedCategoryFromEdit[0].id,
        label: selectedCategoryFromEdit[0].name,
      });

      // Fill story related data.
      fillStories(publishDate, categoryId, roundupSections);
    }
  };

  const fillStories = async (storyDate, storyCategory, roundupSections) => {
    console.log(
      "Getting stories based on this filter",
      storyDate,
      storyCategory,
      roundupSections
    );

    // Get the stories.
    const stories = await StoryService.getStoriesAsync(
      storyDate,
      storyCategory,
      true
    );

    // Check if the story data found.
    if (stories && stories.length > 0) {
      // Filter the selected stories.
      const storySelect = stories.map((item) => {
        return { value: item.id, label: item.headline };
      });

      // Fill the story populate
      setStorySelect(storySelect);

      // Set story in multiselect
      let selectedStories = [];
      if (roundupSections.length > 0) {
        selectedStories = getSelectedStorySection(storySelect, roundupSections);
      }
      setRoundupStories(selectedStories);
    }
  };

  const getSelectedStorySection = (storySelect, roundupIds) => {
    if (storySelect.length <= 0) {
      return;
    }
    if (roundupIds.length <= 0) {
      return;
    }

    let selected = [];
    for (let i = 0; i < roundupIds.length; i++) {
      let selectedOne = storySelect.filter((item) => {
        return item.value === roundupIds[i]["storyId"];
      });
      if (selectedOne.length > 0) {
        selected.push(selectedOne[0]);
      }
    }

    return selected;
  };

  const onSave = async () => {
    // Get the roundup ID from Params. This means this is an edit form.
    console.log("Save Roundup Function Call");
    const roundupID = parseInt(params.id, 10);
    if (roundupID > 0) {
      //  Editing the roundup.
      const roundupSections = roundupStories.map((item) => {
        return { StoryId: item.value };
      });

      // saving the edit form with stories.
      const { success, data } = await RoundupService.edit(roundupID, {
        Id: roundupID,
        PublishDate: publishDate,
        OpeningText: openingtext,
        ExternalURL: externalURL,
        IsPublished: isPublished,
        RoundupSections: roundupSections,
      });

      // Set the response message
      setIsResponseOK(success);

      if (success) {
        setResponseMessage(`Roundup updated.`);
        setTimeout(() => {
          history.push(`/roundup`);
        }, waitTime);
      } else {
        setResponseMessage(`Error while updating roundup: ${data}`);
      }

      // Logic to show error or success.
    } else {
      // Creating the roundup
      const { success, data } = await RoundupService.create({
        PublishDate: publishDate,
        OpeningText: openingtext,
        ExternalURL: externalURL,
        CategoryId: selectedCategory.value,
      });

      console.log(
        "Save Roundup Data",
        publishDate,
        openingtext,
        externalURL,
        selectedCategory.value
      );

      // Set the response message
      setIsResponseOK(success);

      if (success) {
        setResponseMessage(`Roundup created. Add stories to the roundup.`);

        // Move to edit page to select  for the roundup.
        history.push(`/roundup/edit/${data}`);

        // Set the roundup ID in state, as this is the flag to useeffect to reload the data.
        setCurrentRoundupID(data);

        // Set the edit page, as based on this we're showing the story select.
        setIsEditPage(true);
      } else {
        setResponseMessage(`Error while creating roundup: ${data}`);
      }

      // Remove the message after ...
      setTimeout(() => {
        setResponseMessage("");
      }, waitTime);
    }
  };

  return (
    <React.Fragment>
      <div className="mt-4">
        {responseMessage.length > 0 && (
          <div
            className={
              isResponseOK ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {responseMessage}
          </div>
        )}
        <div className="conatiner border mt-5">
          <div className="col-sm-12 border p-5">
            <h3>{pageTitle}</h3>

            <div className="row">
              <div className="col col-3 mt-5 mb-3">
                <label for="roundups" class="form-lable">
                  Publish Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={publishDate}
                  onChange={(event) => setPublishDate(event.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-10 mb-3">
                <label for="roundups" class="form-lable">
                  Opening Text
                </label>
                <textarea
                  type="textarea"
                  name="openingText"
                  className="form-control"
                  row="3"
                  value={openingtext}
                  onChange={(event) => setOpeningText(event.target.value)}
                  placeholder="Enter Opening text"
                />
              </div>
            </div>

            {/* =========Category Select============ */}
            <div className="form-row mb-3">
              <div className="col-sm-3">
                <label for="roundups" class="form-lable sm-3">
                  Category
                </label>
                <Select
                  isDisabled={disableCategory}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  options={categorySelect}
                  components={animatedComponents}
                />
              </div>
            </div>

            {/* =========External URL============ */}

            <div className="form-group mt-4 mb-5">
              <label htmlFor="imageBy">External URL</label>
              <input
                type="email"
                name="externalURL"
                className="form-control"
                value={externalURL}
                onChange={(e) => setExternalURL(e.target.value)}
                placeholder="Enter External URL"
              />
            </div>

            {/* //==========Story multi select dropdown ===== */}
            {isEditPage > 0 && (
              <>
                <div className="form-row my-2">
                  <div className="col-sm-4">
                    <label>
                      <input
                        type="checkbox"
                        name="isPublished"
                        onChange={(e) => setIsPublished(e.target.checked)}
                        checked={isPublished}
                      />
                      <span className="ml-2">Publish roundup</span>
                    </label>
                  </div>
                </div>
                <div className="form-row mb-3">
                  <div className="col-sm-10">
                    <label for="roundups" class="form-lable sm-3">
                      Story
                    </label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={setRoundupStories}
                      isMulti
                      options={storySelect}
                      value={roundupStories}
                    />
                  </div>
                </div>
              </>
            )}
            {/* //==========Story multi select dropdown ===== */}

            <div className="row">
              <div className="col-sm-12 d-flex justify-content-end mb-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    onSave();
                  }}
                >
                  {submitButtonName}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateRoundup;
