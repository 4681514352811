import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import FileUpload from "components/file_upload/FileUpload";

const StoryImageUpload = (props) => {
    
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload image for the Story 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUpload fileUploadType="story" storyid={props.storyid} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

StoryImageUpload.propTypes = {
    storyid:PropTypes.number.isRequired
};

export default StoryImageUpload;
