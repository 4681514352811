class HttpResponseHandler {
  isOkStatus = (response) => {
    if (response.status > 199 && response.status < 300) {
      return true;
    } else {
      return false;
    }
  };

  returnSuccess = (response) => {
    if (this.isOkStatus(response)) {
      return { success: true, failed: false, data: response.data };
    }
  };

  returnError = (response) => {
    let messages = [];
    if (
      response &&
      response.response &&
      response.response.data &&
      response.response.data.errors
    ) {
      Object.keys(response.response.data.errors).forEach((item) => {
        messages.push(response.response.data.errors[item][0]);
      });
    } else {
      messages = [
        `There was error while processing your request. Please contact dev team`,
      ];
    }
    return { success: false, failed: true, data: messages.join() };
  };
}

export default new HttpResponseHandler();
