import React, { useState } from "react";
import UserService from "./UserService";

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");

  const [message, setmessage] = useState("");
  const [isResponseOk, setIsResponseOk] = useState(true);

  const saveData = async () => {
    console.log("Saved");
    setmessage("");

    if (newPassword !== reNewPassword) {
      setmessage("Your new password and Re enter new password is not matching");
      setIsResponseOk(false);
      return;
    }

    // Check
    /**
     * Password have atleast 1 Char in upper case
     * Password have min length 8
     * Password have one digit
     * Password have one Special Char
     *
     */
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (!strongRegex.test(newPassword)) {
      setmessage(
        "Your new password Must have one uppercase, one lowercase, number and special char !@#$%^&* and alteast minimun 8 Character"
      );
      setIsResponseOk(false);
      return;
    }

    const dataTosend = {
      OldPassword: oldPassword,
      NewPassword: newPassword,
    };
    const { data, status } = await UserService.resetUserPassword(dataTosend);
    console.log("Handled data", data, status);

    setmessage(data);
    setIsResponseOk(status);
    setOldPassword("");
    setNewPassword("");
    setReNewPassword("");
  };

  return (
    <div>
      <div className="mt-5">
        {message.length > 0 && (
          <div
            className={
              isResponseOk ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {message}
          </div>
        )}

        <div className="d-flex justify-content-center">
          <div className="col-sm-5 border p-5">
            <h4>Reset Password</h4>

            <div className="container mt-5 ">
              <div className="row">
                <div className="col ">
                  <label for="Reset password" className="form-lable">
                    Enter Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Current password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mt-3">
                  <label for="Reset password" className="form-lable">
                    Enter New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mt-3">
                  <label for="Reset password" className="form-lable">
                    Re-Enter New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={reNewPassword}
                    onChange={(e) => setReNewPassword(e.target.value)}
                    placeholder="Re enter new password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-end mt-3 mb-3">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      saveData();
                    }}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-muted">
          <small>Password Rules</small>
          <ul>
            <li><small>Minimum 8 characters</small></li>
            <li><small>At least one upper case</small></li>
            <li><small>At least one lower case</small></li>
            <li><small>One of the these special characters - !@#$%^&amp;* </small></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
