import React, { Component } from "react";

export default class NewsLetterWhatElseStory extends Component {
  render() {
    return (
      <div>
        <div className="form-row mb-3">
          <div className="col-sm-5">
            <label htmlFor="Category">Select What else story</label>
            <select
              name="StoryId"
              className="form-control"
              onChange={(e) => this.props.populate(this.props.index, e)}
              value={this.props.whatElseStory.StoryId}
            >
              <option value="-1">Select</option>
              {this.props.storiesSelect.map((story, index) => {
                return (
                  <option key={index} value={story.id}>
                    {story.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-1">
            <label htmlFor="Category">&nbsp;</label> <br />
            <button
              type="button"
              onClick={(e) => this.props.removeWhatElse(this.props.index, e)}
              className="btn btn-sm btn-outline-danger"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-trash"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </div>
          <div className="col-sm-5"></div>
        </div>
      </div>
    );
  }
}
