import axios from "axios";

const APPLICATION_URL = process.env.REACT_APP_API_URL;
console.log("Current environment ", process.env.REACT_APP_ENVIRONMENT);
class Auth {
  constructor() {
    this.authenticated = false;
    this.token = null;
    this.API_URL = `${APPLICATION_URL}/Authentication/`;
    this.BASE_URL = APPLICATION_URL;
    this.USER_EMAIL = null;
    this.setErrorResponseInterceptor();
    this.authStorageKeyName = "tbncauthentication";
    this.runningTimer = null;
  }

  login(email, password, cb) {
    console.log("Usser Login Email Id", email);
    this.USER_EMAIL = email;
    axios
      .post(this.API_URL, { Email: email, Password: password })
      .then((response) => {
        this.authenticated = true;
        this.token = response.data.token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
        const tokenExpireyInMilliSeconds = 10 * 60 * 60 * 1000;
        const expireyTime = +new Date() + tokenExpireyInMilliSeconds;
        const authStorageObject = {
          AuthToken: this.token,
          ExpiryTime: expireyTime,
        };
        localStorage.setItem(
          this.authStorageKeyName,
          JSON.stringify(authStorageObject)
        );
        this.setLogoutTimer(tokenExpireyInMilliSeconds);
        cb(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log("data", error.response.data);
          console.log("status", error.response.status);
          console.log("headers", error.response.headers);
          cb(true, error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error log", error.message);
        }
        console.log(error.config);
        cb(true, error.message);
      });
  }

  test(one) {
    console.log(one);
  }

  setLogoutTimer(timeInMilliSeconds) {
    var that = this;
    this.runningTimer = setTimeout(() => {
      that.logout(() => {
        console.log("Logging out as user is done");
      });
    }, timeInMilliSeconds);
  }

  setErrorResponseInterceptor() {
    console.log("Interceptor call");

    const UNAUTHORIZED = 401;
    axios.interceptors.response.use(
      (response) => {
        // console.log("Interceptor Response Success", response);
        return response;
      },
      (error) => {
        console.log("Interceptor Response Error", error);
        if (error.response && error.response.status) {
          const { status } = error.response;
          if (status === UNAUTHORIZED) {
            console.log("Interceptor Got UnAuthrized Token");
            this.setLogoutTimer(5);
          }
        }
        console.log("Error Data", error);
        return Promise.reject(error);
      }
    );
  }

  logout(cb) {
    this.authenticated = false;
    this.token = null;
    axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem(this.authStorageKeyName);

    // Clear timeout if one is running.
    if (this.runningTimer) {
      clearTimeout(this.runningTimer);
    }
    cb();
  }

  isAuthenticated() {
    // return this.authenticated;
    // console.log("isAuthenticated Running Timer", this.runningTimer);

    const authData = localStorage.getItem(this.authStorageKeyName);
    if (authData) {
      try {
        const authObject = JSON.parse(authData);

        if (!authData) {
          return false;
        }

        const { AuthToken, ExpiryTime } = authObject;
        if (!AuthToken || !ExpiryTime) {
          return false;
        }

        const currentTime = +new Date();
        if (currentTime > ExpiryTime) {
          return false;
        }

        // Set Token
        axios.defaults.headers.common["Authorization"] = `Bearer ${AuthToken}`;
        if (!this.runningTimer) {
          const newTimeInMilliSeconds = ExpiryTime - currentTime;
          console.log("Setting new Time for", newTimeInMilliSeconds);
          this.setLogoutTimer(newTimeInMilliSeconds);
        }
      } catch (error) {
        console.log(
          "There was an error while checking Authentication by user",
          error
        );
        return false;
      }
      return true;
    }
    return false;
  }

  getToken() {
    return this.token;
  }

  getBaseURL() {
    return this.BASE_URL;
  }

  getUserEmail() {
    return this.USER_EMAIL;
  }
}

export default new Auth();
