import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";
import ErrorParser from "components/common/Parser/ErrorParser";

class RoundService {
  // Data retrive from the server BY Date

  retrieve = async (dateFilter) => {
    const response = await axios.get(
      `${Auth.getBaseURL()}/Roundups?dateFrom=${dateFilter}`
    );

    if (this.isOkStatus(response)) {
      return await response.data;
    }
    return null;
  };

  //======= Retrieve by id data =======

  retrieveById = async (roundupId) => {
    console.log("Data retrieve by id output ");
    const response = await axios.get(
      `${Auth.getBaseURL()}/Roundups/${roundupId}`
    );

    console.log("output end retrieve by id", response);

    if (this.isOkStatus(response)) {
      console.log("response.data retrieve id");
      return response.data;
    }
    return null;
  };

  // ==========Data send to server for store=====//

  create = async (data) => {
    try {
      console.log("Create roundup with data", data);
      const response = await axios.post(`${Auth.getBaseURL()}/Roundups`, data);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  };

  // edit service

  edit = async (id, data) => {
    try {
      console.log("for edit retrive call", data);
      const response = await axios.put(
        `${Auth.getBaseURL()}/Roundups/${id}`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  };

  // delete service

  delete = async (id) => {
    try {
      const response = await axios.delete(
        `${Auth.getBaseURL()}/Roundups/${id}`
      );
      console.log("Delete roundup response: ", response);

      if (this.isOkStatus(response)) {
        return response.data;
      }
    } catch (error) {
      console.log("Error while removing the roundup", error.response);

      const errors = ErrorParser.parseErrorMessage(error, "Delete Roundup");
      console.log("Error log", errors);
    }

    return null;
  };

  // response function

  isOkStatus = (response) => {
    if (response.status > 199 && response.status < 300) {
      return true;
    } else {
      return false;
    }
  };
}
export default new RoundService();
