import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import ContactService from "./ContactService";

export const ContactPaginate = ({ contactPageNumberChange }) => {
  return (
    <div>
      <div className="contactpagelist">
        <ReactPaginate
          previousPage={"previous"}
          nextPage={"next"}
          breakLabel={". . ."}
          breakClassName={"break-me page-numbers"}
          pageCount={100} //====================== Page range set========//
          marginPagesDisplayed={3}
          pageRangeDisplayed={4}
          onPageChange={contactPageNumberChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};
export default ContactPaginate;
