import React, { Component } from "react";
import StoryService from "components/story/StoryService";
import NewsLetterStory from "./NewsLetterStory";
import NewsLetterService from "components/newsletter/NewsLetterService";
import NewsletterRoundup from "./NewsletterRoundup";
import RoundupService from "components/roundup/RoundupService";
import MarketDataService from "components/marketData/MarketDataService";
import NewsLetterProgressBar from "./NewsLetterProgressBar";
import ProgressBarService from "components/ProgressBar/ProgressBarService";

const ProgressBarSection = "ProgressBar";
const StorySection = "Story";

export default class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorMessages: [],
      PageName: "Create Newsletter",

      GeneratedPartnership: false,
      SponsorCaptionContent: "",
      SponsorImageUrl: "",
      PartnershipUrl: "",

      GeneratedAlert: false, // Add Alert Text Button Function Variable
      AlertText: "", // Input Text Area value
      AlertHeading: "",

      GeneratedFigureTable: false,
      MarketDataId: -1,

      Stories: [],
      NewsletterSectionBullets: [],
      Categories: [],

      GeneratedStories: [],
      GeneratedProgressbar: [],

      StoriesSelect: [],
      CategoriesSelect: [],

      PublishedDate: "",
      OpeningText: "",
      PreviewText: "",
      EmailSubject: "",
      ClosingText: "Thanks for reading!",

      GeneratedRoundups: [],
      RoundupSelect: [],
      RoundupMap: [],

      MarketDataSelect: [],
      ProgressBarData: [],
      ProgressOptions: [],
      ProgressBarId: -1,

      Process: "Create",
      ID: -1,
      CategoryMap: {},

      // DRAG AND DROP
      DragItemNumber: -1,
      IsDroppable: false,
    };
  }

  async componentDidMount() {
    // Get the NewsLetter data
    const newsLetterId = parseInt(this.props.match.params.id, 10);
    await this.fillCategories();

    if (newsLetterId > 0) {
      // Edit process.
      this.setState({
        PageName: "Edit Newsletter",
        Process: "Edit",
        ID: newsLetterId,
      });

      // Fill the progress options
      const progressData = await this.getProgressData();

      const { data, success } = await NewsLetterService.getByIdAsync(
        newsLetterId
      );
      console.log("Newsletter data", data);
      if (success) {
        // set the data
        const filterDate = data.publishDate.slice(0, 10);
        await this.populateStories(filterDate);
        // console.log("Filled roundups", this.state.RoundupSelect);
        console.log("Newsletter Roundup groups: ", data.roundupGroups);

        let newsLetterData = data.newsletterSections.map((item) => {
          if (
            item.workoutText !== undefined &&
            item.workoutText.trim() !== "" &&
            item.workoutLink !== undefined &&
            item.workoutLink.trim() !== ""
          ) {
            return {
              CategoryId: null,
              StoryId: null,
              WorkoutText: item.workoutText,
              WorkoutLink: item.workoutLink,
              NewsletterSectionBullets: [],
              SectionType: ProgressBarSection,
            };
          } else {
            return {
              CategoryId: item.categoryId,
              StoryId: item.storyId,
              WorkoutText: item.workoutText,
              WorkoutLink: item.workoutLink,
              NewsletterSectionBullets: item.sectionBullets.map((b) => {
                return {
                  StoryId: b.storyId,
                };
              }),
              SectionType: StorySection,
            };
          }
        });

        let roundupData = data.roundupGroups.map((item) => {
          let selectedRoundups = [];
          for (let i = 0; i < item.roundupGroupTiles.length; i++) {
            let currentTile = item.roundupGroupTiles[i];
            let label = `${currentTile.roundup.publishDate.substring(0, 10)}-${
              currentTile.roundup.category.shortName
            }`;
            let value = currentTile.roundupId;
            selectedRoundups.push({ label: label, value: value });
          }

          return {
            SectionHeader: item.title,
            SelectedRoundups: selectedRoundups,
          };
        });

        this.setState(
          {
            PublishedDate: data.publishDate.slice(0, 10),
            EmailSubject: data.emailSubject,
            OpeningText: this.replaceBreaksWithNewLine(data.openingText),
            PreviewText: data.previewText,
            GeneratedStories: newsLetterData,
            GeneratedRoundups: roundupData,

            SponsorCaptionContent: data.sponsorCaptionContent,
            SponsorImageUrl: data.sponsorImageUrl,
            PartnershipUrl: data.partnershipUrl,
            GeneratedPartnership:
              data.sponsorCaptionContent || data.sponsorImageUrl,

            AlertHeading: data.alertHeading,
            AlertText: data.alertContent,
            GeneratedAlert: data.alertContent && data.alertContent.length > 0,

            MarketDataId: data.marketDataId,
            ProgressBarId: data.progressBarId,
            GeneratedFigureTable: data.marketDataId && +data.marketDataId > 0,
          },
          () => {
            this.setProgressOptionById(+this.state.ProgressBarId);
          }
        );
      } else {
        console.log(
          "There was an error while getting the data from the newsletter",
          data
        );
      }
    }
  }

  addStory = (sectionName) => {
    let workoutText = "",
      workoutURL = "";
    if (
      sectionName === ProgressBarSection &&
      this.state.ProgressOptions.length > 0
    ) {
      workoutText = this.state.ProgressOptions[0].name;
      workoutURL = this.state.ProgressOptions[0].url;
    }

    this.setState({
      GeneratedStories: [
        ...this.state.GeneratedStories,
        {
          CategoryId: -1,
          StoryId: -1,
          WorkoutText: workoutText,
          WorkoutLink: workoutURL,
          NewsletterSectionBullets: [],
          SectionType: sectionName,
        },
      ],
    });
  };

  addProgress = async () => {
    const progressData = await this.getProgressData();
    console.log("Progress data ", progressData);
  };

  getProgressData = async () => {
    const { data, success } = await ProgressBarService.retrieve();
    if (success === true) {
      this.setState({
        ProgressBarData: data,
        ProgressOptions: data[0].sectionList,
        ProgressBarId:
          data[0].sectionList.length > 0 ? data[0].sectionList[0].id : 0,
      });
      return data;
    } else {
      console.log("There was an error while getting the progress data");
    }
    return [];
  };

  removeStory = (index) => {
    console.log("Reoving Story", index);
    let copyGeneratedStories = [...this.state.GeneratedStories];
    copyGeneratedStories.splice(index, 1);
    this.setState({ GeneratedStories: copyGeneratedStories });
  };

  onPublishDateChange = (event) => {
    this.populate(event);
    let selectedDate = event.target.value;
    this.populateStories(selectedDate);
  };

  // Populate stories based on date.
  populateStories = async (selectedDate) => {
    // Get the stories Data
    let dateFilter = selectedDate || StoryService.getDefaultDate();

    const stories = await StoryService.getStoriesAsync(dateFilter, "-1");

    if (typeof stories === "undefined") {
      console.log("Error while getting the stories.");
    } else {
      const storiesSelect = stories.map((item) => {
        return { name: item.headline, id: item.id };
      });

      this.setState({ Stories: stories, StoriesSelect: storiesSelect });
    }

    // fill the roundupa based on date.
    await this.fillRoundups(dateFilter);

    // fill finance table
    await this.fillMarketData(dateFilter);
  };

  fillRoundups = async (dateFilter) => {
    // fill roundups
    const allRoundups = await RoundupService.retrieve(dateFilter);

    // Get only published roundups for the newsletter.
    const roundups = allRoundups.filter((item) => {
      return item.isPublished === true;
    });

    console.log("Found published roundups", roundups);
    if (roundups !== null) {
      const select = roundups.map((item) => {
        return {
          label: `${item.publishDate.substring(0, 10)}-${
            this.state.CategoryMap[item.categoryId]
          }`,
          value: item.id,
        };
      });

      let roundupMap = {};
      for (let i = 0; i < roundups.length; i++) {
        roundupMap[roundups[i]["id"]] = `${roundups[i].publishDate.substring(
          0,
          10
        )}-${this.state.CategoryMap[roundups[i].categoryId]}`;
      }

      this.setState({ RoundupSelect: select, RoundupMap: roundupMap });
    }
  };

  fillCategories = async () => {
    // Get the category data
    const { data, status } = await StoryService.getCategoriesAsync();

    if (status) {
      let categoryMap = {};
      for (let i = 0; i < data.length; i++) {
        categoryMap[data[i]["id"]] = data[i]["shortName"];
      }
      this.setState({ CategoriesSelect: data, CategoryMap: categoryMap });
    } else {
      console.log("There was some issue in getting categories.");
      this.setState({
        errorMessages: "There was some issue in getting categories.",
      });
    }
  };

  fillMarketData = async (dateFilter) => {
    const { data, success } = await MarketDataService.retreive(dateFilter);

    if (success === true && data) {
      const selectOptions = data.map((item) => {
        return { Id: item.id, Name: item.publishDate.substring(0, 10) };
      });
      // console.log("Options before set", selectOptions);
      this.setState({ MarketDataSelect: selectOptions });
    }
  };

  populate = (event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [key]: val });
  };

  newsLetterStoryPopulate = (index, event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    this.state.GeneratedStories[index][key] = val;

    this.setState({ GeneratedStories: [...this.state.GeneratedStories] });
  };

  setStoryWhatElse = (index, whatElseStories) => {
    console.log("Setting what else on home page ", index, whatElseStories);
    let copyGeneratedStories = [...this.state.GeneratedStories];
    copyGeneratedStories[index]["NewsletterSectionBullets"] =
      whatElseStories.map((item) => {
        return { ...item, StoryId: parseInt(item.StoryId, 10) };
      });
    this.setState({ GeneratedStories: copyGeneratedStories });
  };

  setProgressData = (index, text, url) => {
    const cloneGeneratedStories = [...this.state.GeneratedStories];
    cloneGeneratedStories[index]["WorkoutText"] = text;
    cloneGeneratedStories[index]["WorkoutLink"] = url;

    this.setState({
      GeneratedStories: cloneGeneratedStories,
    });
  };

  replaceNewLineWithBreak = (input) => {
    if (input && input.length > 0) {
      return input.replace(/\r\n|\r|\n/g, "<br/>");
    }
    return input;
  };

  replaceBreaksWithNewLine = (input) => {
    if (input && input.length > 0) {
      return input.replace(/<br\s*\/?>/gi, "\r\n");
    }
    return input;
  };

  save = () => {
    let data = {
      PublishDate: this.state.PublishedDate,
      OpeningText: this.replaceNewLineWithBreak(this.state.OpeningText),
      AlertHeading: this.state.GeneratedAlert ? this.state.AlertHeading : "",
      AlertContent: this.state.GeneratedAlert ? this.state.AlertText : "",

      PartnershipUrl: this.state.PartnershipUrl,
      SponsorCaptionContent: this.state.GeneratedPartnership
        ? this.state.SponsorCaptionContent
        : "",
      SponsorImageUrl: this.state.GeneratedPartnership
        ? this.state.SponsorImageUrl
        : "",

      MarketDataId: this.state.GeneratedFigureTable
        ? +this.state.MarketDataId
        : null,
      ProgressBarId: +this.state.ProgressBarId,
      PreviewText: this.state.PreviewText,
      EmailSubject: this.state.EmailSubject,
      NewsletterSections: this.state.GeneratedStories.map((item, index) => {
        const categoryId = +item.CategoryId;
        const storyId = +item.StoryId;

        return {
          ...item,
          CategoryId: categoryId > 0 ? categoryId : null,
          StoryId: storyId > 0 ? storyId : null,
          OrderNumber: index,
        };
      }),
      ClosingText: this.state.ClosingText,
      NewsletterRoundups: this.state.GeneratedRoundups.map((item) => {
        return {
          Title: item.SectionHeader,
          RoundupIds: item.SelectedRoundups.map((roundup) => {
            return parseInt(roundup.value, 10);
          }),
        };
      }),
    };

    if (this.state.Process === "Edit") {
      data = { ...data, Id: this.state.ID };

      NewsLetterService.update(data, (error, data) => {
        if (error) {
          console.log("Error while saving data ");
        } else {
          console.log("Saved!!");
          console.log(data);
          this.props.history.push(`/newsletter`);
        }
      });
    } else {
      NewsLetterService.create(data, (error, data) => {
        if (error) {
          console.log("Error while saving data ");
        } else {
          console.log("Saved!!");
          console.log(data);
          this.props.history.push(`/newsletter`);
        }
      });
    }
  };

  addAlertBox = () => {
    this.setState({
      GeneratedAlert: !this.state.GeneratedAlert,
    });
  };

  addFigureTable = () => {
    this.setState({
      GeneratedFigureTable: !this.state.GeneratedFigureTable,
    });
  };

  addPartnershipContentBox = () => {
    this.setState({
      GeneratedPartnership: !this.state.GeneratedPartnership,
    });
  };

  addProgressbar = () => {
    this.setState({
      GeneratedProgressbar: [...this.state.GeneratedProgressbar, {}],
    });
  };

  addRoundup = () => {
    // Add the roundup options here.
    this.setState({
      GeneratedRoundups: [
        ...this.state.GeneratedRoundups,
        { SectionHeader: "", SelectedRoundups: [] },
      ],
    });
  };

  onRoundupSelectChange = (selectedRoundups, index) => {
    // console.log("Selected roundups", selectedRoundups, "Index", index);
    const allGeneratedRoundups = [...this.state.GeneratedRoundups];
    allGeneratedRoundups[index].SelectedRoundups = selectedRoundups;
    this.setState({ GeneratedRoundups: allGeneratedRoundups });
  };

  setRoundupHeader = (value, index) => {
    // console.log("Selected text", value, index);
    const allGeneratedRoundups = [...this.state.GeneratedRoundups];
    allGeneratedRoundups[index].SectionHeader = value;
    this.setState({ GeneratedRoundups: allGeneratedRoundups });
  };

  onRoundupSectionRemove = (index) => {
    console.log("Removing the item at index", index);
    const allGeneratedRoundups = [...this.state.GeneratedRoundups];
    allGeneratedRoundups.splice(index, 1);
    this.setState({ GeneratedRoundups: allGeneratedRoundups });
  };

  setProgressOptions = (event) => {
    // console.log("Selected ID", event.target.value);
    this.setProgressOptionById(+event.target.value);
  };

  setProgressOptionById = (id) => {
    const selectedOption = this.state.ProgressBarData.filter((item) => {
      return item.id === id;
    });
    if (selectedOption.length > 0) {
      this.setState({
        ProgressOptions: selectedOption[0].sectionList,
        ProgressBarId: id,
      });
    }
  };

  /** DRAG AND DROP FUNCTION */
  handleDrag = (e) => {
    // console.log("Handle drag", e.currentTarget.id);
    this.setState({ DragItemNumber: e.currentTarget.id });
  };
  handleDrop = (ev) => {
    // console.log("Handle drop", this.state.DragItemNumber, ev.currentTarget.id);

    // both are same no need to reorder.
    if (this.state.DragItemNumber === ev.currentTarget.id) {
      return;
    }

    const cloneGeneratedStories = [...this.state.GeneratedStories];

    const storyToInsertAtNewIndex =
      cloneGeneratedStories[this.state.DragItemNumber];

    // remove item from array
    cloneGeneratedStories.splice(this.state.DragItemNumber, 1);

    cloneGeneratedStories.splice(
      ev.currentTarget.id,
      0,
      storyToInsertAtNewIndex
    );
    // console.log("New order", cloneGeneratedStories);

    this.setState({
      GeneratedStories: cloneGeneratedStories,
      DragItemNumber: -1,
      IsDroppable: false,
    });
  };
  handleDragOver = (ev) => {
    ev.preventDefault();
    // console.log("On drag over change function");
  };

  render() {
    const progressBarButtonToggler =
      this.state.ProgressBarData.length > 0 && this.state.ProgressBarId;

    return (
      <div className="mt-5">
        <div className="">
          <div className="col-sm-12 border p-5">
            <h3>{this.state.PageName}</h3>

            <div className="mt-5">
              {this.state.errorMessage}

              <form>
                <div className="form-row mb-3">
                  <div className="mb-3">
                    <label htmlFor="PublishedDate">Published Date</label>
                    <div className="row  justify-content-start ml-1">
                      <div className="">
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => this.onPublishDateChange(e)}
                          name="PublishedDate"
                          placeholder="Enter PublishedDate"
                          value={this.state.PublishedDate}
                        />
                      </div>
                      {/* =================================================== */}
                      {this.state.Process === "Edit" ? (
                        <div className="col">
                          <div className="ml-5">
                            <button
                              className="btn btn-secondary btn-sm"
                              type="button"
                              onClick={this.addAlertBox}
                            >
                              {this.state.GeneratedAlert
                                ? "Hide Alert Content"
                                : "Add Alert Content"}
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {/* ===================================================== */}
                      {this.state.Process === "Edit" ? (
                        <div className="ml-5">
                          <div className="">
                            <button
                              className="btn btn-secondary btn-sm"
                              type="button"
                              onClick={this.addFigureTable}
                            >
                              {this.state.GeneratedFigureTable
                                ? "Hide Figure First"
                                : "Add Figure First"}
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {/* ====================================================== */}
                      {this.state.Process === "Edit" ? (
                        <div className="ml-5">
                          <div className="">
                            <button
                              className="btn btn-secondary btn-sm"
                              type="button"
                              onClick={this.addPartnershipContentBox}
                            >
                              {this.state.GeneratedPartnership
                                ? "Hide Partnership"
                                : "Add Partnership"}
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* ==================================(Partnership Field)========================================= */}
                  {this.state.GeneratedPartnership && (
                    <div className="container-fluid nl-custom-box nl-partnership-bg pb-3">
                      <div className="form-row">
                        <div className="col-sm-4">
                          <div className="">
                            <label>Partnership Text</label>
                            <br />
                            <div className="">
                              <input
                                type="text"
                                name="SponsorCaptionContent"
                                className="form-control"
                                onChange={this.populate}
                                value={this.state.SponsorCaptionContent}
                                placeholder="Enter Partnership Text"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="">
                            <label>Partnership Image Url</label>
                            <br />
                            <input
                              type="url"
                              name="SponsorImageUrl"
                              className="form-control"
                              onChange={this.populate}
                              value={this.state.SponsorImageUrl}
                              placeholder="Enter Partnership Image URL"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="">
                            <label>Partnership Url</label>
                            <br />
                            <input
                              type="url"
                              name="PartnershipUrl"
                              className="form-control"
                              onChange={this.populate}
                              value={this.state.PartnershipUrl}
                              placeholder="Enter Partnership Image URL"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* ==============================(Opening Text)================================================== */}

                <div className="form-group mb-3">
                  <label htmlFor="OpeningText">Opening Text</label>
                  <textarea
                    type="textarea"
                    rows="3"
                    className="form-control"
                    onChange={this.populate}
                    name="OpeningText"
                    placeholder="Enter Opening text or Good morning text"
                    value={this.state.OpeningText}
                  ></textarea>
                </div>
                {/* ===============================(Alert Content)================================================ */}
                {this.state.GeneratedAlert && (
                  <div>
                    <div className="nl-alert-bg nl-custom-box">
                      <div className="form-group mb-3">
                        <label htmlFor="AlertHeading">Alert Headline</label>
                        <div className="">
                          <input
                            type="text"
                            name="AlertHeading"
                            className="form-control"
                            onChange={this.populate}
                            value={this.state.AlertHeading}
                            placeholder="Enter Alert Heading"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="AlertText">Alert Content</label>
                        <div className="">
                          <textarea
                            type="textarea"
                            name="AlertText"
                            rows="3"
                            className="form-control"
                            onChange={this.populate}
                            value={this.state.AlertText}
                            placeholder="Enter Alert Story"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ============================================================================================== */}
                <div className="form-group mb-3">
                  <label htmlFor="EmailSubject">Email Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.populate}
                    name="EmailSubject"
                    placeholder="Subject of the email "
                    value={this.state.EmailSubject}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="PreviewText">Preview Text</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.populate}
                    name="PreviewText"
                    placeholder="Keeping you informed on the key events that transpired in the last 24 hours."
                    value={this.state.PreviewText}
                  />
                </div>
                {/* ============================(Figure First Table)============================================== */}
                {this.state.GeneratedFigureTable && (
                  <div className="row">
                    <div className="col-4 form-group mb-3">
                      <label htmlFor="FigureTable">Figure First</label>
                      <select
                        name="MarketDataId"
                        className="form-control"
                        onChange={this.populate}
                        value={this.state.MarketDataId}
                      >
                        <option value="-1">Select Market Data</option>
                        {this.state.MarketDataSelect.map((item, index) => {
                          return (
                            <option key={index} value={item.Id}>
                              {item.Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                {/* ==============================(Roundup Section)=============================================== */}
                <div className="form-row mt-4 mb-4">
                  <button
                    className="btn btn-secondary btn-sm"
                    type="button"
                    onClick={this.addRoundup}
                  >
                    Add Roundup Section
                  </button>
                </div>

                {this.state.GeneratedRoundups.map((item, index) => {
                  return (
                    <NewsletterRoundup
                      key={index}
                      index={index}
                      roundup={item}
                      roundupSelect={this.state.RoundupSelect}
                      onChangeRoundupSelect={this.onRoundupSelectChange}
                      removeRoundup={this.onRoundupSectionRemove}
                      onChangeRoundupLabelHeader={this.setRoundupHeader}
                    />
                  );
                })}
                {/* ==============================(Add Story Section)============================================= */}
                <div className="form-group mb-3">
                  <button
                    className="btn btn-secondary btn-sm"
                    type="button"
                    onClick={() => this.addStory(StorySection)}
                  >
                    Add Story Section
                  </button>
                  {!progressBarButtonToggler ? (
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm ml-3"
                      onClick={this.addProgress}
                    >
                      Add Progress Bar
                    </button>
                  ) : (
                    <div className="d-inline-block">
                      <select
                        className="ml-5"
                        onChange={this.setProgressOptions}
                        value={this.state.ProgressBarId}
                      >
                        {this.state.ProgressBarData.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        className="btn btn-secondary btn-sm ml-3"
                        type="button"
                        onClick={() => this.addStory(ProgressBarSection)}
                      >
                        Add Progress Bar Section
                      </button>
                    </div>
                  )}
                </div>
                {/* ===========================(Add ProgressBar Section)========================================== */}
                {this.state.GeneratedStories.map((story, idx) => {
                  if (story.SectionType === ProgressBarSection) {
                    return (
                      <NewsLetterProgressBar
                        key={idx}
                        index={idx}
                        progressData={this.state.ProgressBarData}
                        setProgressData={this.setProgressData}
                        progressOptions={this.state.ProgressOptions}
                        story={story}
                        removeSection={this.removeStory}
                        handleDrag={this.handleDrag}
                        handleDrop={this.handleDrop}
                        isDroppable={this.state.IsDroppable}
                        handleDragOver={this.handleDragOver}
                      />
                    );
                  }
                  return (
                    <NewsLetterStory
                      key={idx}
                      story={story}
                      index={idx}
                      populate={this.newsLetterStoryPopulate}
                      categoriesSelect={this.state.CategoriesSelect}
                      storiesSelect={this.state.StoriesSelect}
                      addNewsLetterWhatElse={this.state.addNewsLetterWhatElse}
                      setStoryWhatElse={this.setStoryWhatElse}
                      removeStory={this.removeStory}
                      handleDrag={this.handleDrag}
                      handleDrop={this.handleDrop}
                      isDroppable={this.state.IsDroppable}
                      handleDragOver={this.handleDragOver}
                    />
                  );
                })}
                {/* ============================================================================================== */}

                <div className="form-group mb-3 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.save}
                  >
                    Save News Letter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
