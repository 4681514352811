import React, { useState, useEffect } from "react";
import ContactService from "./ContactService";
import ContactTable from "./ContactTable";

export default function ContactList(props) {
  const [contacts, setContacts] = useState([]);
  const [pageName, setPageName] = useState("Contacts List");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  useEffect(() => {
    retrieve();
  }, [pageIndex]);

  const retrieve = async () => {
    console.log("called retrieve for Data output");
    const data = await ContactService.retrieve(pageIndex, pageSize);
    setContacts(data);
    console.log("finished retrieve output call");
  };

  // ============Pagination select contact===================

  const handlePageClick = (data) => {
    let selected = data.selected;
    console.log("main page Handle page clicked", selected);
    setPageIndex(selected);
  };

  const createContact = () => {
    props.history.push(`/contact/create`);
  };

  return (
    <div>
      <div className="mt-5">
        <div className="">
          <div className="col-sm-12">
            <h3>{pageName}</h3>
          </div>
          <div className="col-sm-12 my-2">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={createContact}
              >
                Create Contact
              </button>
            </div>
          </div>
        </div>
      </div>
      <ContactTable
        contactsData={contacts}
        onPageNumberClick={handlePageClick}
      />
    </div>
  );
}
