import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap';

const FileUploadMessage = ({message}) => {
  const [show, setShow] =  useState(true);

    if(show){
        return (
            <Alert variant="info" onClose={() => setShow(false)} dismissible>
              <p>
                {message}
              </p>
            </Alert>
          );  
    }
    return null;
}

FileUploadMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default FileUploadMessage


