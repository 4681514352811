import {
  CREATE_PROGRESSBAR,
  RETRIEVE_PROGRESSBAR,
  RETRIEVE_BY_ID_PROGRESSBAR,
  UPDATE_PROGRESSBAR,
  DELETE_PROGRESSBAR,
} from "./ProgressbarActionType";

import ProgressBarService from "../../../components/ProgressBar/ProgressBarService";

// =====================================================================================================================

export const createProgressbar = (apiData) => async (dispach, getAction) => {
  console.log("Action => API Post Request to Server", apiData);
  try {
    const { data, success } = await ProgressBarService.create(apiData);
    console.log("Action => API Post response", apiData, success);
    dispach({ type: CREATE_PROGRESSBAR, payload: data });
  } catch (error) {
    console.log("Action API Post Response - Error", error);
  }
};

// ---------------------------------------------------------------------------

export const retrieveProgressbar = () => async (dispach, getAction) => {
  console.log("Action => API Retrieve Request to Server");
  try {
    const { data, success } = await ProgressBarService.retrieve();
    console.log("Action => API Retrieve response", data, success);
    dispach({ type: RETRIEVE_PROGRESSBAR, payload: data });
  } catch (error) {
    console.log("Action API Retrieve Response - Error", error);
  }
};

// ---------------------------------------------------------------------------

export const retrieveByIdProgressbar = (id) => async (dispach, getAction) => {
  console.log("Action => API RetrieveById Request to Server");
  try {
    const { data, success } = await ProgressBarService.retrieveProgressDatabyID(
      id
    );
    console.log("Action => Data RetrieveById Response", data, success);
    dispach({ type: RETRIEVE_BY_ID_PROGRESSBAR, payload: data });
  } catch (error) {
    console.log("Action API RetrieveById Response - Error", error);
  }
};

// ---------------------------------------------------------------------------

export const updateProgressbar =
  (progressDataId, apiData, id) => async (dispach, getAction) => {
    console.log("Action => API Update Request to Server", progressDataId);
    try {
      const { data, success } = await ProgressBarService.edit(
        progressDataId,
        apiData
      );
      console.log("Action => API Update/Edit Response", data, success);
      dispach({ type: UPDATE_PROGRESSBAR, payload: data });
    } catch (error) {
      console.log("Action API Update/Edit Response - Error", error);
    }
  };

// --------------------------------------------------------------------------

export const deleteProgressbar = (id) => async (dispach, getAction) => {
  console.log("Action => API Delete Request to Server");
  try {
    const { data, success } = await ProgressBarService.delete(id);
    console.log("Action => API Delete response", success, data);
    dispach({ type: DELETE_PROGRESSBAR, payload: data });
  } catch (error) {
    console.log("Action API Delete Response - Error", error);
  }
};

// =====================================================================================================================
