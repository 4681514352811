import React, { useState, useEffect } from "react";

import { PageMask } from "components/common/PageMask";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import StoryService from "../StoryService";
import DraftEditor from "components/editor/DraftEditor";
import { convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  EDITOR_BLOCK,
  EDITOR_INLINE_BOLD,
  EDITOR_INLINE_ITALIC,
  EDITOR_INLINE_UNDERLINE,
  EDITOR_LINK,
  EDITOR_TEAL_BOLD,
  EDITOR_UL,
  EDITOR_UNORDERED_LIST,
  EDITOR_BTN_LINK,
} from "components/editor/DraftClassNameConstants";

const animatedComponents = makeAnimated();

const CreateStory = ({ match }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [pageName, setPageName] = useState("Create Story");
  const [responseMessage, setResponseMessage] = useState("");
  const [responseAlertType, setResponseAlertType] = useState("success");
  const [allCategories, setAllCategories] = useState("");
  const [categoriesSelect, setCategoriesSelect] = useState("");
  const [, setStoryAPIResponse] = useState({});

  const [storyId, setStoryId] = useState(-1);
  const [publishedDate, setPublishedDate] = useState("");
  const [headline, setHeadline] = useState("");
  const [imageBy, setImageBy] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [hideHeadline, setHideHeadline] = useState(false);
  const [category, setCategory] = useState("");
  const [additionalCategories, setAdditionalCategories] = useState([]);
  const [externalURL, setExternalURL] = useState("");
  const [content, setContent] = useState("");
  const [lineNumber, setLineNumber] = useState(1);
  const [clipAfterLineNumber, setClipAfterLineNumber] = useState("");
  const [readMoreOptions, setReadMoreOptions] = useState([]);
  const [totalLines, setTotalLines] = useState(0);
  const [editorJSON, setEditorJSON] = useState({});
  const [contentState, setContentState] = useState("");

  const [dataToFill, setDataToFill] = useState("");
  const [isContentAvailable, setIsContentAvailable] = useState(false);

  /**
   * Use Effect to load the category data for the story list.
   */

  /**
   * Save the data
   *
   */

  useEffect(() => {
    fillCategories();
    // Load the stories of edit one.
  }, []);

  useEffect(() => {
    if (categoriesSelect.length > 0) {
      const {
        params: { storyid },
      } = match;
      // console.log("storyID", storyid, typeof storyid);
      if (storyid > -1) {
        setStoryId(+storyid);

        fillStoryDetails(+storyid);
      } else {
        setIsContentAvailable(true);
      }
    }
  }, [categoriesSelect]);

  useEffect(() => {
    const options = [];
    for (let i = 1; i <= totalLines; i++) {
      options.push({ text: i, value: i });
    }
    setReadMoreOptions(options);
  }, [totalLines]);

  useEffect(() => {
    if (responseMessage.length) {
      window.scrollTo(0, 0);
    }
  }, [responseMessage]);

  const fillStoryDetails = async (id) => {
    // Get the story details by the ID
    setIsLoading(true);
    setPageName("Edit Story");
    const { success, data } = await StoryService.getStorieByIDAsync(id);
    console.log(data);
    if (success === true) {
      // Put the data
      setStoryAPIResponse(data);
      const editorJSONData = JSON.parse(data.editorState);
      setTotalLines(editorJSONData.blocks.length);
      setPublishedDate(data.publishDate.slice(0, 10));
      setHeadline(data.headline);
      setHideHeadline(data.hideHeadline);
      setImageBy(data.imageBy);
      setIsPublished(data.isPublished);
      setExternalURL(data.externalURL);
      setEditorJSON(editorJSONData);
      setContentState(convertFromRaw(editorJSONData));
      setClipAfterLineNumber(data.clipAfterLineNumber);

      // console.log("Category data ", data.category.name, data.category.id);
      setCategory({ label: data.category.name, value: data.category.id });
      // Additional Category Data Population

      const selectedAdditionalCategories = data.storyCategories.map((item) => {
        return {
          label: getCategoryText(item.categoryId),
          value: item.categoryId,
        };
      });

      // console.log("Additional Categories", selectedAdditionalCategories);
      setAdditionalCategories(selectedAdditionalCategories);
    } else {
      showErrorMessage("Error while retrieving the story to edit");
    }

    setIsContentAvailable(true);
    setIsLoading(false);
  };

  const getCategoryText = (id) => {
    for (let index = 0; index < categoriesSelect.length; index++) {
      if (categoriesSelect[index].value === id) {
        return categoriesSelect[index].label;
      }
    }

    return "";
  };

  const resetResponseMessage = () => {
    setTimeout(() => {
      setResponseMessage("");
    }, 5000);
  };

  const showErrorMessage = (message) => {
    // Error response came
    setResponseMessage(message);
    setResponseAlertType("danger");
    resetResponseMessage();
  };

  const showSuccessMessage = (message) => {
    // Error response came
    setResponseMessage(message);
    setResponseAlertType("success");
    resetResponseMessage();
    history.push({ pathname: "/story" });
  };

  const fillCategories = async () => {
    const { data, status } = await StoryService.getCategoriesAsync();

    if (status === false) {
      showErrorMessage("Error while retrieving the categories");
    } else {
      const select = data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setAllCategories(data);
      setCategoriesSelect(select);
    }
  };

  const setExtractedHTMLParts = (contentState) => {
    /**
     * 01. Extract the before and after json
     * 02. Set the before json to editor and get the HTML
     * 03. Set the after json to editor and get the HTML
     * 04. Save the data.
     */
    let beforeHTML, afterHTML;

    const options = {
      inlineStyles: {
        // Override default element (`strong`).
        TEALBOLD: {
          // style: {
          //   color: EDITOR_TEAL,
          //   fontWeight: EDITOR_FONT_WEIGHT,
          //   fontSize: EDITOR_FONT_SIZE,
          // },
          attributes: { class: EDITOR_TEAL_BOLD },
        },
        UNDERLINE: {
          element: "span",
          attributes: { class: EDITOR_INLINE_UNDERLINE },
        },
        ITALIC: {
          element: "span",
          attributes: { class: EDITOR_INLINE_ITALIC },
        },
        BOLD: {
          element: "span",
          attributes: { class: EDITOR_INLINE_BOLD },
        },
      },
      blockStyleFn: (block) => {
        // console.log("Kaushik block style", block.getData());
        if (block.getType() === EDITOR_UL) {
          return {
            // style: {
            //   marginTop: 10,
            //   marginBottom: 10,
            //   fontSize: "18px",
            // },
            attributes: {
              class: EDITOR_UNORDERED_LIST,
            },
          };
        } else {
          return {
            // style: {
            //   fontSize: "18px",
            // },
            attributes: { class: EDITOR_BLOCK },
          };
        }
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();

        if (entityType === "link") {
          const data = entity.getData();
          return {
            element: "a",
            attributes: {
              href: data.url,
              target: "_blank",
              class: data.checked === true ? EDITOR_BTN_LINK : EDITOR_LINK,
            },
          };
        }
      },
    };

    if (clipAfterLineNumber > 0) {
      const beforeReadMore = convertToRaw(contentState);

      beforeReadMore.blocks = beforeReadMore.blocks.slice(
        0,
        +clipAfterLineNumber
      );

      beforeHTML = stateToHTML(convertFromRaw(beforeReadMore), options);

      const afterReadmore = convertToRaw(contentState);
      afterReadmore.blocks = afterReadmore.blocks.slice(+clipAfterLineNumber);
      afterHTML = stateToHTML(convertFromRaw(afterReadmore), options);
    } else {
      beforeHTML = stateToHTML(contentState, options);
      afterHTML = "";
    }

    // Return this if someone wants immediately.
    return { beforeHTML, afterHTML };
  };

  const logState = (json, html, contentState) => {
    // (JSON.stringify(json));
    setContent(html);
    setEditorJSON(json);
    setContentState(contentState);
  };

  const save = async () => {
    setIsLoading(true);
    const { beforeHTML, afterHTML } = setExtractedHTMLParts(contentState);
    console.log(
      "Saves the data",
      publishedDate,
      headline,
      hideHeadline,
      imageBy,
      isPublished,
      category,
      externalURL,
      additionalCategories,
      beforeHTML,
      afterHTML
    );

    const storyDetails = {
      Headline: headline,
      HideHeadline: hideHeadline,
      CategoryId: parseInt(category.value, 10),
      IsPublished: isPublished,
      PublishDate: publishedDate,
      ImageBy: imageBy,
      ExternalURL: externalURL,
      AdditionalCategories: additionalCategories.map((item) => {
        return parseInt(item.value, 10);
      }),
      ClipAfterLineNumber: +clipAfterLineNumber,
      HTMLContent: beforeHTML,
      AdditionalHTMLContent: afterHTML,
      EditorState: JSON.stringify(editorJSON),
    };

    let apiResponse;

    console.log("Pushing story data: ", storyDetails);
    if (storyId > -1) {
      storyDetails.Id = storyId;
      apiResponse = await StoryService.editAsync(storyDetails);
    } else {
      apiResponse = await StoryService.createAsync(storyDetails);
    }

    // Extract the data for the api response.
    const { success, data } = apiResponse;

    console.log("Story change for the data=>", data);

    // Read the response now here.
    if (success) {
      showSuccessMessage("Saved successfully");
    } else {
      showErrorMessage(
        "There was an error while saving the story. Please contact dev team."
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="mt-5">
      {isLoading && <PageMask />}

      <div className="">
        <div className="col-sm-12 border p-5">
          <h3>{pageName}</h3>
          <div className="mt-5">
            {responseMessage.length > 0 ? (
              <Alert variant={responseAlertType}>{responseMessage}</Alert>
            ) : null}

            <form>
              <div className="form-row mb-3">
                <div className="col-sm-3">
                  <label htmlFor="PublishedDate">Published Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setPublishedDate(e.target.value)}
                    placeholder="Enter PublishedDate"
                    value={publishedDate}
                  />
                </div>
                <div className="col-sm-9"></div>
              </div>

              <div className="form-group mb-3">
                <div className="row">
                  <div className="">
                    <label htmlFor="headline" className="pl-3 pr-3">
                      Hide Headline?
                    </label>
                  </div>
                  <div className="">
                    <input
                      type="checkbox"
                      onChange={(e) => setHideHeadline(e.target.checked)}
                      name="hideHeadline"
                      checked={hideHeadline}
                    />
                  </div>
                </div>

                {/* {hideHeadline && ( */}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setHeadline(e.target.value)}
                  placeholder="Enter headline"
                  value={headline}
                />
                {/* // )} */}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="imageBy">Image By</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setImageBy(e.target.value)}
                  placeholder="Illustration source: John doe"
                  value={imageBy}
                />
              </div>

              <div className="form-row mb-3">
                <div className="col-sm-2">
                  <label htmlFor="Category">Category</label>
                  <Select
                    value={category}
                    onChange={setCategory}
                    options={categoriesSelect}
                    components={animatedComponents}
                    closeMenuOnSelect={true}
                  />
                </div>
                <div className="col-sm-10"></div>
              </div>

              <div className="form-row mb-3">
                <div className="col-sm-5">
                  <label htmlFor="AdditionalCategories">
                    Additional categories
                  </label>

                  <Select
                    value={additionalCategories}
                    onChange={setAdditionalCategories}
                    options={categoriesSelect}
                    isMulti
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="col-sm-7"></div>
              </div>

              <div className="form-group mb-3">
                <div>
                  <label className="pr-3">Publish?</label>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsPublished(e.target.checked)}
                    name="IsPublished"
                    checked={isPublished}
                  />
                </div>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="imageBy">External URL</label>
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => setExternalURL(e.target.value)}
                  placeholder="Enter External URL"
                  value={externalURL}
                />
              </div>

              <div className="form-row mb-3">
                <div className="col-sm-2">
                  <label htmlFor="ClipAfterLineNumber">
                    Read more after line number
                  </label>
                  <select
                    name="ClipAfterLineNumber"
                    className="form-control"
                    onChange={(e) => setClipAfterLineNumber(e.target.value)}
                    value={clipAfterLineNumber}
                  >
                    <option value="-1">Select</option>
                    {readMoreOptions.map((c, index) => {
                      return (
                        <option key={index} value={c.value}>
                          {c.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-10"></div>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="emailContent">
                  Email content | You are at line number{" "}
                  <span className="text-primary font-weight-bold">
                    {" "}
                    {lineNumber}
                  </span>
                </label>
                <div className="bg-light p-5 border-light rounded-corner">
                  {isContentAvailable && (
                    <DraftEditor
                      save={logState}
                      setLineNumber={setLineNumber}
                      setTotalLines={setTotalLines}
                      dataToFill={editorJSON}
                      isContentAvailable={isContentAvailable}
                    />
                  )}
                </div>
              </div>

              <div className="form-group mb-3 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={save}
                >
                  Save Story
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStory;
