import axios from "axios";
import Auth from "components/auth/Auth";

class userService {
  resetUserPassword = async (resetPassword) => {
    console.log("Service 1 ");

    try {
      const response = await axios.post(
        `${Auth.getBaseURL()}/Users/ChangePassword`,
        resetPassword
      );

      console.log("API Response", response);

      if (response.status >= 200 && response.status < 300) {
        // Response correct aaya hai.
        console.log("Returning data");

        return { data: response.data, status: true };
      }
    } catch (error) {
      console.log("Error", error);
      console.log("Response error", error.response.data);
      return { data: error.response.data, status: false };
    }

    return {
      data: "There was an error processing your request. Contact Admin",
      status: false,
    };
  };
}
export default new userService();
