/*
 * MarketData Common Action Type
 */

export const CREATE_MARKETDATA = "CREATE_MARKETDATA";
export const RETRIEVE_MARKETDATA = "RETRIEVE_MARKETDATA";

export const RETRIEVE_BY_ID_MARKETDATA = "RETRIEVE_BY_ID_MARKETDATA";
export const UPDATE_MARKETDATA = "UPDATE_MARKETDATA";

export const DELETE_MARKETDATA = "DALETE_MARKETDATA";
