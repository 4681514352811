import { PROCESS } from "redux/processConstants";
import {
  CREATE_PROGRESSBAR,
  RETRIEVE_PROGRESSBAR,
  RETRIEVE_BY_ID_PROGRESSBAR,
  UPDATE_PROGRESSBAR,
  DELETE_PROGRESSBAR,
} from "./ProgressbarActionType";

const initialState = {
  ProgressListItem: [],
  progressSetData: {},
  progressGetById: {},
  progressUpdateStore: {},
  ProgressDeleteItem: false,
  process: "",
};

const progressbarReducer = (state = initialState, action) => {
  // const { type, payload } = action;
  switch (action.type) {
    case CREATE_PROGRESSBAR:
      console.log("Reducess Call CREATE_PROGRESSBAR");
      return {
        ...state,
        progressSetData: action.payload,
        process: PROCESS.CREATE,
      };

    case RETRIEVE_PROGRESSBAR:
      console.log("Reducer Call RETRIEVE_PROGRESSBAR");
      return {
        ...state,
        ProgressListItem: action.payload,
        process: PROCESS.RETRIEVE,
      };

    case RETRIEVE_BY_ID_PROGRESSBAR:
      console.log("Reducess Call RETRIEVE_BY_ID_PROGRESSBAR");
      return {
        ...state,
        progressGetById: action.payload,
        process: PROCESS.RETRIEVE_BY_ID,
      };

    case UPDATE_PROGRESSBAR:
      console.log("Reducess Call UPDATE_PROGRESSBAR");
      return {
        ...state,
        progressUpdateStore: action.payload,
        process: PROCESS.UPDATE,
      };

    case DELETE_PROGRESSBAR:
      console.log("Reducess Call DELETE_PROGRESSBAR");
      return {
        ...state,
        ProgressDeleteItem: action.payload,
        process: PROCESS.DELETE,
      };

    default:
      return state;
  }
};

export default progressbarReducer;
