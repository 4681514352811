import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";
import ErrorParser from "components/common/Parser/ErrorParser";

class NewsLetterService {
  // Create a newsletter for the Morning emails
  create = (newsLetter, cb) => {
    axios
      .post(`${Auth.getBaseURL()}/Newsletters`, newsLetter)
      .then((res) => {
        console.log("Saved Newsletter", res);
        cb(false, res.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "NewsLetter Create"
        );
        console.log(messages.join(","));
        cb(true, messages);
      });
  };

  // Updating a newsletter for the Morning emails
  update = (newsLetter, cb) => {
    axios
      .put(`${Auth.getBaseURL()}/Newsletters/${newsLetter.Id}`, newsLetter)
      .then((res) => {
        console.log("Updated Newsletter", res);
        cb(false, res.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "NewsLetter Update"
        );
        cb(true, messages);
      });
  };

  // Retrieving a newsletter for the Morning emails
  retrieve = (fromDate, cb) => {
    axios
      .get(`${Auth.getBaseURL()}/Newsletters?DateFrom=${fromDate}`)
      .then((res) => {
        console.log("Got all Newsletter", res);
        cb(false, res.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "NewsLetter Retrieve"
        );
        cb(true, messages);
      });
  };

  // Get a newsletter by ID(Usually for update process) for the Morning emails
  getByIdAsync = async (id) => {
    try {
      const response = await axios.get(
        `${Auth.getBaseURL()}/Newsletters/${id}`
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error.response);
    }
  };

  getById = (id, cb) => {
    axios
      .get(`${Auth.getBaseURL()}/Newsletters/${id}`)
      .then((res) => {
        console.log("Got Newsletter by ID", res);
        cb(false, res.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "NewsLetter Get By ID"
        );
        cb(true, messages);
      });
  };

  // Deleting newsletter by ID
  delete = (id, cb) => {
    axios
      .delete(`${Auth.getBaseURL()}/Newsletters/${id}`)
      .then((res) => {
        console.log("Deleted Newsletter by ID", res);
        cb(false, res.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "NewsLetter Delete"
        );
        cb(true, messages);
      });
  };

  getGeneratedNewsletter = (id, cb) => {
    axios
      .get(`${Auth.getBaseURL()}/GenerateNewsletter/${id}`)
      .then((response) => {
        console.log("Deleted Newsletter by ID", response);
        cb(false, response.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "Generating Newsletter by ID"
        );
        cb(true, messages);
      });
  };

  sendTestEmail = (id, emails, enableTracking, cb) => {
    axios
      .post(`${Auth.getBaseURL()}/Newsletters/SendTestEmail`, {
        NewsletterId: id,
        EmailAddress: emails,
        EnableTracking: enableTracking,
      })
      .then((response) => {
        console.log("Newsletter sending test email", response);
        cb(false, response.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(
          error,
          "Sending Test email"
        );
        cb(true, messages);
      });
  };

  scheduleEmail = (id, scheduleAt, cb) => {
    axios
      .post(`${Auth.getBaseURL()}/Newsletters/SecheduleEmail`, {
        NewsletterId: id,
        ScheduleUnixTime: scheduleAt,
      })
      .then((response) => {
        console.log("Newsletter Scheduling email", response);
        cb(false, response.data);
      })
      .catch((error) => {
        let messages = ErrorParser.parseErrorMessage(error, "Schedule Email");
        cb(true, messages);
      });
  };

  cancelSchedule = async (NewsletterId) => {
    try {
      console.log("NL Cancel Schedule API Service Call ", NewsletterId);
      const response = await axios.post(
        `${Auth.getBaseURL()}/Newsletters/Cancel/${NewsletterId}`
      );
      console.log("NL API Schedule Cancel response Success", response);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("NL Cancel Schedule Service API Response Error");
      return HttpResponseHandler.returnError(error.response);
    }
  };
}

export default new NewsLetterService();
