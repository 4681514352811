import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import CategoryService from "./CategoryService";
// -----------------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../../redux/actions/category/CategoryAction";
import { getByIdCategory } from "../../redux/actions/category/CategoryAction";
import { updateCategory } from "../../redux/actions/category/CategoryAction";
import { PROCESS } from "redux/processConstants";

const Category = () => {
  const history = useHistory();
  const params = useParams();

  const [responseErrorMessage, setResponseErrorMessage] = useState("");
  const [isResponseOk, setIsResponseOk] = useState(true);
  const [pageName, setPageName] = useState("Create Category");

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");

  const [categoryDetails, setCategoryDetails] = useState([]);

  const [categoryID, setCategoryID] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.CAT);
  const { categoryGetByIdData, process } = categoryState;

  useEffect(() => {
    console.log("UseEffect Called");
    if (+params.id > 0) {
      setPageName("Edit Category");
      setIsEdit(true);
      setCategoryID(+params.id);
      getCategoryByID(+params.id);
    }
  }, []);

  // For create category
  useEffect(() => {
    debugger;
    if (process === PROCESS.CREATE || process === PROCESS.UPDATE) {
      history.push("/category");
    }
  }, [process]);

  useEffect(() => {
    if (categoryID > 0) {
      // console.log("Edit Category Data get by ID", categoryGetByIdData);
      setName(categoryGetByIdData.name);
      setShortName(categoryGetByIdData.shortName);
      setCategoryDetails({ Name: name, ShortName: shortName });
    }
  }, [categoryGetByIdData]);

  const getCategoryByID = async (id) => {
    console.log("Edit Category Id", id);
    dispatch(getByIdCategory(id));
  };

  const removeAlertMessage = (timeoutInms) => {
    setTimeout(() => {
      setResponseErrorMessage("");
    }, timeoutInms);
  };

  const save = async () => {
    console.log("Save Category function called");

    let apiData = {
      Name: name,
      ShortName: shortName,
    };
    // console.log("API Post Data", apiData);

    if (isEdit === true) {
      apiData.Id = categoryID;
      dispatch(updateCategory(apiData, categoryID));
    } else {
      dispatch(createCategory(apiData));
    }
  };

  return (
    <React.Fragment>
      <div className="mt-5">
        <div>
          {responseErrorMessage.length > 0 && (
            <div
              className={
                isResponseOk ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {responseErrorMessage}
            </div>
          )}
        </div>
        <div className="">
          <div className="col-sm-12 border p-5">
            <h3>{pageName}</h3>
            <div className="mt-5">
              <form>
                <div className="form-group">
                  <label htmlFor="Name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    name="name"
                    placeholder="Enter Name"
                    value={name}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ShortName">Short Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setShortName(e.target.value)}
                    name="shortName"
                    placeholder="Enter Image Url"
                    value={shortName}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={save}
                  >
                    save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Category;
