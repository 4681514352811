import React, { useState } from "react";

function DNDChild({
  email,
  handleDrag,
  handleDrop,
  currentIndex,
  handleDragOver,
  isDroppable,
}) {
  return (
    <div
      id={currentIndex}
      draggable
      onDragOver={handleDragOver}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      className={"draggable p-5 m-5 border" + (isDroppable ? " droppable" : "")}
    >
      {email}
    </div>
  );
}

export default DNDChild;
