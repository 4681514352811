import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";
import ErrorParser from "components/common/Parser/ErrorParser";

class CategoryService {
  async create(data) {
    try {
      // console.log("Category Service Post to server");
      const response = await axios.post(
        `${Auth.getBaseURL()}/Categories`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Category Service Post Error", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async getCategoryByID(id) {
    try {
      const response = await axios.get(`${Auth.getBaseURL()}/Categories/${id}`);
      console.log("Category Service API GetById Response", id);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Category Service Retrieve Error", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async edit(categoryID, data) {
    try {
      const response = await axios.put(
        `${Auth.getBaseURL()}/Categories/${categoryID}`,
        data
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Category Service Edit Error Response", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async retrieve() {
    try {
      const response = await axios.get(`${Auth.getBaseURL()}/Categories`);
      // console.log("Category Service Get Response to Server", response);
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Category Service Get Error", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  async deleteCategory(categoryID) {
    try {
      // console.log("Category Service Delete request");
      const response = await axios.delete(
        `${Auth.getBaseURL()}/Categories/${categoryID}`
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("Category Service Delete Error Response", error);
      const errors = ErrorParser.parseErrorMessage(error, "Delete Category");
      return HttpResponseHandler.returnError(error.response);
    }
  }
}

export default new CategoryService();
