import React, { useEffect, useState } from "react";
import MarketTable from "./MarketTable";
import DeletePrompt from "components/common/DeletePrompt";
import Helper from "components/common/Helper";
import MarketDataService from "./MarketDataService";
import { useHistory } from "react-router";
// ------------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { retrieveMarketdata } from "../../redux/actions/marketData/MarketdataAction";
import { deleteMarketdata } from "../../redux/actions/marketData/MarketdataAction";

const MarketList = () => {
  const history = useHistory();
  const [publishDate, setPublishDate] = useState(Helper.getDate());
  const [marketData, setmarketData] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [marketDataId, setMarketDataId] = useState(-1);

  const dispatch = useDispatch();
  const marketdataReducer = useSelector((state) => state.MDR);
  const { MarketdataListItem, MarketdataDeleteItem } = marketdataReducer;

  useEffect(() => {
    retreive();
  }, [publishDate, MarketdataDeleteItem]);

  const retreive = async () => {
    console.log("Market data Retrieve in List");
    dispatch(retrieveMarketdata(publishDate));
  };

  const gotocreateMarketData = () => {
    console.log("Create Market Data Handler");
    history.push(`/financedata/create`);
  };

  const editTikerData = (marketDataId) => {
    console.log("Edit market Data From List");
    history.push(`financedata/edit/${marketDataId}`);
  };

  const deleteTikerData = (marketDataId) => {
    console.log("Delete Matket Data from List", marketDataId);
    setShowDeletePrompt(true);
    setMarketDataId(marketDataId);
    console.log(marketDataId);
  };

  const onDeleteMarketDataCancel = () => {
    console.log("Delete Cancel ");
    setShowDeletePrompt(false);
  };

  const DeleteMarketDataConfirm = async () => {
    console.log("Delete Market Data COnfirm", marketDataId);
    dispatch(deleteMarketdata(marketDataId));
    retreive();
    setShowDeletePrompt(false);
    setMarketDataId(-1);
  };

  return (
    <div>
      <DeletePrompt
        show={showDeletePrompt}
        header="Delete Roundup"
        body="Are you Sure want to delete this roundup"
        onHide={onDeleteMarketDataCancel}
        onSuccess={DeleteMarketDataConfirm}
      />

      <div className="mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h3> Market Data</h3>
          </div>
          <div className="col-sm-12 mt-3">
            <div className="col-sm-3">
              <label htmlFor="publishDate" className="mr-3">
                Date filter
              </label>
              <input
                type="date"
                name="publishDate"
                value={publishDate}
                onChange={(event) => setPublishDate(event.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-12 my-2">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={gotocreateMarketData}
              >
                Create Market Data
              </button>
            </div>
          </div>
        </div>
      </div>

      <MarketTable
        marketDataSet={MarketdataListItem}
        EditMarketData={editTikerData}
        DeleteMarketData={deleteTikerData}
      />
    </div>
  );
};

export default MarketList;
