import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";

class SendEmailService {
  async sendTestEmail(emailDetails) {
    try {
      const response = await axios.post(
        `${Auth.getBaseURL()}/SendEmail/Test`,
        emailDetails
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error);
    }
  }

  async sendEmailToContatcts(emailDetails) {
    try {
      const response = await axios.post(
        `${Auth.getBaseURL()}/SendEmail/Send`,
        emailDetails
      );
      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      return HttpResponseHandler.returnError(error);
    }
  }
}

export default new SendEmailService();
