class ErrorParser {
    parseErrorMessage(input,pageName){
        let messages = []
        if(input && input.response && input.response.data &&  input.response.data.errors){
            Object.keys(input.response.data.errors).forEach((item) => {
                messages.push(input.response.data.errors[item][0]);
              });
        }else{
            messages=[`[${pageName}] - There was error while processing your request`]
        }
        console.log(messages.join(","));
        return messages;
    }

}

export default new ErrorParser()