import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  CompositeDecorator,
  EditorBlock,
} from "draft-js";
import {
  EDITOR_BLOCK,
  EDITOR_FONT_WEIGHT,
  EDITOR_LINK,
  EDITOR_TEAL,
  EDITOR_UL,
  EDITOR_UNDERLINE,
  EDITOR_UNORDERED_LIST,
  EDITOR_BTN_LINK,
  EDITOR_BTN_BORDER,
} from "./DraftClassNameConstants";
import "draft-js/dist/Draft.css";
import "components/common/editor/editor-style.css";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const Link = (props) => {
  const { url, checked } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  return (
    <a
      href={url}
      className={checked === true ? EDITOR_BTN_LINK : EDITOR_LINK}
      style={checked === true ? styles.btnLink : styles.link}
      target="_blank"
    >
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export default class DraftEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: null,
      showURLInput: false,
      urlValue: "",
      edit: false,
      completeHTML: "",
      partialHTML: "",
      editorJSON: null,
      isEditorLoaded: false,
      isAddButton: false,
    };
    this.editorRef = React.createRef();
    this.inputURLRef = React.createRef();

    this.focus = () => this.editorRef.current.focus();

    this.onChange = (editorState) =>
      this.setState({ editorState }, () => {
        this.logState();
      });

    this.myRef = React.createRef();
  }

  componentDidMount() {
    // setTimeout(this.autoFillData, 2000);
    // console.log("Component did mount Draft js data ", this.props.dataToFill);
    this.autoFillData(this.props.dataToFill);
    // console.log("Test data mera wala: ", this.props.dataToFill);
  }

  logState = () => {
    // console.log("Started", +new Date());
    const content = this.state.editorState.getCurrentContent();

    const currentBlockKey = this.state.editorState.getSelection().getStartKey();
    const blockMap = this.state.editorState.getCurrentContent().getBlockMap();

    const currentBlockIndex = blockMap
      .keySeq()
      .findIndex((k) => k === currentBlockKey);

    // console.log("Line number", currentBlockIndex, blockMap.length);

    // console.log("Current content", this.editorRef.current.editor.innerHTML);
    const jsonContent = convertToRaw(content);

    // console.log(JSON.stringify(jsonContent));
    this.props.save(
      jsonContent,
      this.editorRef.current.editor.innerHTML,
      content
    );

    if (this.props.setLineNumber) {
      this.props.setLineNumber(currentBlockIndex + 1);
    }

    if (this.props.setTotalLines) {
      this.props.setTotalLines(blockMap.length);
    }

    // this.setState(
    //   {
    //     editorJSON: jsonContent,
    //     completeHTML: this.editorRef.current.editor.innerHTML,
    //   },
    //   this.saveStory
    // );
    // this.setPartialContentHTML(jsonContent, 6);
  };

  autoFillData = (data) => {
    if (data && Object.keys(data).length > 0) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(data),
          decorator
        ),
        isEditorLoaded: true,
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(decorator),
        isEditorLoaded: true,
      });
    }
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  _promptForLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = "";
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.inputURLRef.current.focus(), 0);
        }
      );
    }
  };

  _confirmLink = (e) => {
    // console.log("Confirm Button Link");
    e.preventDefault();
    const { editorState, urlValue, isAddButton } = this.state;
    // console.log("Confirm Button Link", editorState, urlValue, isAddButton);
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlValue, class: "tbnc-email-link", checked: isAddButton }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState(
      {
        editorState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        ),
        showURLInput: false,
        urlValue: "",
      },
      () => {
        setTimeout(() => this.editorRef.current.focus(), 0);
      }
    );
  };

  _onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      this._confirmLink(e);
    }
  };

  _removeLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  };

  updateReadonly = (e) => {
    e.preventDefault();
    this.setState({ edit: true });
  };

  onURLChange = (e) => this.setState({ urlValue: e.target.value });

  addButton = (e) => this.setState({ isAddButton: e.target.checked });

  toggleBulletPoints = () => {
    const newEditorState = RichUtils.toggleInlineStyle(
      this.state.editorState,
      "unordered-list-item"
    );
    this.setState({ editorState: newEditorState });
  };
  /*
  setPartialContentHTML(jsonObj, numberOfLines){ */
  /**
   * Based on number of lines pick the blocks
   * Fill the blocks into the editor again
   * Populate to the editor
   * Re read the content from editor to get the HTML
   * Return the HTML
   */
  /*
      const partialContent = { blocks:jsonObj.blocks.slice(0,numberOfLines), entityMap: {...jsonObj.entityMap} } ;
      
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(partialContent),
          decorator
        )
      }, this.partialComponentUpdateDone);
      
  } 
  */
  /*
  partialComponentUpdateDone=()=>{
    const content = this.state.editorState.getCurrentContent();
    const jsonContent = convertToRaw(content);
    this.setState({
      partialHTML:this.editorRef.current.editor.innerHTML
    }, this.saveStory);
  }
*/

  saveStory = () => {
    // console.log("Saving the story data");
    // console.log("Complete: ", this.state.completeHTML);
    // console.log("End", +new Date());
    this.props.save(this.state.editorJSON, this.state.completeHTML);
  };

  render() {
    let urlInput;
    if (this.state.showURLInput) {
      urlInput = (
        <div style={styles.urlInputContainer}>
          <input
            onChange={this.onURLChange}
            ref="url"
            style={styles.urlInput}
            type="text"
            value={this.state.urlValue}
            onKeyDown={this._onLinkInputKeyDown}
            ref={this.inputURLRef}
          />
          <button onMouseDown={this._confirmLink}>Confirm</button>
          <label htmlFor="headline" className="pl-3 pr-3">
            Is Add Button?
          </label>

          <input
            type="checkbox"
            onChange={this.addButton}
            name="isAddButton"
            checked={this.state.isAddButton}
          />
        </div>
      );
    }

    // =============================================================

    // Fill the data if the content is passed.
    // this.autoFillData(dataToFill);
    const { isEditorLoaded } = this.state;

    if (isEditorLoaded) {
      return (
        <div>
          <BlockStyleControls
            editorState={this.state.editorState}
            onToggle={this.toggleBlockType}
          />

          <InlineStyleControls
            editorState={this.state.editorState}
            onToggle={this.toggleInlineStyle}
          />

          <div style={styles.buttons}>
            <button
              type="button"
              onMouseDown={this._promptForLink}
              style={{ marginRight: 10 }}
            >
              Add Link
            </button>
            <button type="button" onMouseDown={this._removeLink}>
              Remove Link
            </button>
          </div>

          {urlInput}

          {/* =================================================== */}

          <div style={styles.editor}>
            <Editor
              editorState={this.state.editorState}
              onChange={this.onChange}
              placeholder="Type content or copy paste here"
              ref={this.editorRef}
              readOnly={this.state.edit}
              customStyleMap={customStyle}
              blockStyleFn={blockStyleFn}
              // customStyleFn={customStyleFn}
            />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Teal Bold", style: "TEALBOLD" },
  // { label: "Separator", style: "SEPARATOR" },
  /* { label: "Monospace", style: "CODE" }, */
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}{" "}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  /* { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" }, */
  // {label: 'Blockquote', style: 'blockquote'},
  { label: "Bullet", style: EDITOR_UL },
  // { label: "Go deeper", style: "GODEEPER" },
  // {label: 'OL', style: 'ordered-list-item'},
  // {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

class Line extends React.Component {
  render() {
    const blockMap = this.props.contentState.getBlockMap().toArray();
    const blockKey = this.props.block.key;
    const lineNumber =
      blockMap.findIndex((block) => blockKey === block.key) + 1;
    return (
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "5px" }}>{lineNumber}</span>
        <div style={{ flex: "1" }}>
          <EditorBlock {...this.props} />
        </div>
      </div>
    );
  }
}

class UnorderedList extends Component {
  render() {
    return (
      <ul>
        <li>1</li>
      </ul>
    );
  }
}

const blockRendererFn = function (contentBlock) {
  const type = contentBlock.getType();
  // console.log(type);
  switch (type) {
    default:
      return { component: UnorderedList, editable: true };
  }
};

const blockStyleFn = function (block) {
  const type = block.getType();
  const blockStyles = [];
  switch (type) {
    case EDITOR_UL:
      return EDITOR_UNORDERED_LIST;
    default:
      return EDITOR_BLOCK;
  }
};

const customStyleFn = function (style, block) {
  const type = block.getType();
  // console.log("Type of ", type);
  switch (type) {
    case EDITOR_UL:
      return { display: "inline-block", marginTop: "15px" };
    case "unstyled":
      return { display: "inline-block", marginTop: "15px", lineHeight: "1.6" };
    default:
      return {};
  }
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "2px solid #d4d4d4",
    cursor: "text",
    minHeight: 200,
    maxHeight: 500,
    padding: 10,
    marginBottom: 10,
    overflow: "auto",
    borderRadius: "5px",
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    color: "#3b5998",
    textDecoration: "none",
    borderBottom: EDITOR_UNDERLINE,
  },

  btnLink: {
    color: "#3b5998",
    textAlign: "center",
    textDecoration: "none",
    border: EDITOR_BTN_BORDER,
    borderRadius: "6px",
    display: "block",
    width: "max-content",
    margin: "5px auto",
    padding: "6px 18px",
  },
};

const customStyle = {
  TEALBOLD: {
    color: EDITOR_TEAL,
    fontWeight: EDITOR_FONT_WEIGHT,
  },
};

const blockRenderMap = {
  "unordered-list-item": {
    element: "li",
    wrapper: <ul />,
  },
};
