/*
 * Common Action type
 */

// -------------------------------------------------------------------

export const CREATE_PROGRESSBAR = "CREATE_PROGRESSBAR";
export const RETRIEVE_PROGRESSBAR = "RETRIEVE_PROGRESSBAR";

export const RETRIEVE_BY_ID_PROGRESSBAR = "RETRIEVE_BY_ID_PROGRESSBAR";

export const UPDATE_PROGRESSBAR = "UPDATE_PROGRESSBAR";
export const DELETE_PROGRESSBAR = "DELETE_PROGRESSBAR";
