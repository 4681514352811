import React from "react";

const RoundupTable = ({ roundupList, editRoundup, deleteRoundup }) => {
  return (
    <div>
      <div NameName="container">
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped">
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="roundup-id">
                    #
                  </th>
                  <th scope="col" className="roundup-date">
                    Publish Date
                  </th>
                  <th scope="col" className="roundup-category">
                    Category
                  </th>
                  <th scope="col" className="roundup-opening-text">
                    Opening Text
                  </th>
                  <th scope="col" className="roundup-actions">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {roundupList.map((roundup, index) => {
                  const {
                    id,
                    publishDate,
                    CategoryName,
                    openingText,
                    roundupHash,
                    isPublished,
                  } = roundup;
                  return (
                    <tr key={index}>
                      <th scope="row" className="roundup-id">
                        {id}
                      </th>
                      <td className="roundup-date">
                        {publishDate.substring(0, 10)}
                      </td>
                      <td className="roundup-category">{CategoryName}</td>
                      <td className="roundup-opening-text">{openingText}</td>
                      <td className="roundup-actions">
                        <button
                          type="button"
                          className="btn btn-outline-info mx-1"
                          onClick={(e) => {
                            editRoundup(e, id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            ></path>
                          </svg>
                        </button>

                        {/* //================== Roundup preview========================// */}
                        {/* {roundup.isPublished ? ( ) : ("")}  */}
                        {isPublished === true && (
                          <a
                            href={`https://news.boringnews.co/roundup?date=${publishDate.substring(
                              0,
                              10
                            )}&category=${CategoryName.toLowerCase()}&roundup=${roundupHash}`}
                            target="_blank"
                            className="btn btn-outline-info mx-1"
                            rel="noreferrer"
                            type="button"
                            className="btn btn-outline-info mx-1"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-eye"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              />
                            </svg>
                          </a>
                        )}

                        {/* //================== Roundup preview========================// */}

                        <button
                          type="button"
                          className="btn btn-outline-danger mx-1"
                          onClick={(e) => {
                            deleteRoundup(e, id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundupTable;
