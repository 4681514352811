import React, { Component } from "react";

export class StyleButton extends Component {
  constructor(props) {
    super(props);
  }
  onToggle = (e) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };
  render() {
    let className = "editor-button-contols";
    if (this.props.active) {
      className += " editor-active-contols";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}{" "}
      </span>
    );
  }
}

export default StyleButton;
