import React from "react";

const NewsLetterProgressBar = ({
  index,
  progressData,
  setProgressData,
  progressOptions,
  story,
  removeSection,
  handleDragOver,
  handleDrag,
  handleDrop,
  isDroppable,
}) => {
  const onProgressSelect = (event, index) => {
    const text = event.target.options[event.target.selectedIndex].text;
    const url = event.target.value;
    setProgressData(index, text, url);
  };
  return (
    <div className="">
      <div
        className="mb-4 bg-progress rounded draggable"
        id={index}
        draggable
        onDragOver={handleDragOver}
        onDragStart={handleDrag}
        onDrop={handleDrop}
      >
        <div className="col-sm-12 border rounded p-3">
          {/* =================================(Add-Input-Field for News latter Add story)====================================================== */}

          <div className="form-row mb-1">
            <div className="col-sm-12 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={() => removeSection(index)}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-trash"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="form-row mb-3">
            <div className="col-sm-3">
              <select
                className="form-control"
                onChange={(e) => onProgressSelect(e, index)}
                value={story.WorkoutLink}
              >
                {progressOptions.map((item, idx) => {
                  return (
                    <option key={idx} value={item.url}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-sm-9"></div>
          </div>
          <div className="form-row mb-3">
            <div className="col-sm-4">
              <div className="">
                <label>Workout Text</label>
                <br />
                <div className="">
                  <input
                    type="text"
                    name="WorkoutText"
                    className="form-control cursor-not-allowed"
                    readOnly={true}
                    value={story.WorkoutText}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 ">
              <div className="ml-5 pl-5">
                <label>Workout Url</label>
                <br />
                <input
                  type="url"
                  name="WorkoutLink"
                  className="form-control cursor-not-allowed"
                  readOnly={true}
                  value={story.WorkoutLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetterProgressBar;
