import { convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  EDITOR_BLOCK,
  EDITOR_INLINE_BOLD,
  EDITOR_INLINE_ITALIC,
  EDITOR_INLINE_UNDERLINE,
  EDITOR_LINK,
  EDITOR_TEAL_BOLD,
  EDITOR_UL,
  EDITOR_UNORDERED_LIST,
} from "components/editor/DraftClassNameConstants";

export const getExtractedHTMLParts = (contentJson, clipAfterLineNumber) => {
  /**
   * 01. Extract the before and after json
   * 02. Set the before json to editor and get the HTML
   * 03. Set the after json to editor and get the HTML
   * 04. Save the data.
   */
  const contentState = convertFromRaw(contentJson);
  clipAfterLineNumber = clipAfterLineNumber ? +clipAfterLineNumber : 0;

  let beforeHTML, afterHTML;

  const options = {
    inlineStyles: {
      TEALBOLD: {
        attributes: { class: EDITOR_TEAL_BOLD },
      },
      UNDERLINE: {
        element: "span",
        attributes: { class: EDITOR_INLINE_UNDERLINE },
      },
      ITALIC: {
        element: "span",
        attributes: { class: EDITOR_INLINE_ITALIC },
      },
      BOLD: {
        element: "span",
        attributes: { class: EDITOR_INLINE_BOLD },
      },
    },
    blockStyleFn: (block) => {
      if (block.getType() === EDITOR_UL) {
        return {
          attributes: {
            class: EDITOR_UNORDERED_LIST,
          },
        };
      } else {
        return {
          attributes: { class: EDITOR_BLOCK },
        };
      }
    },
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();

      if (entityType === "link") {
        const data = entity.getData();
        return {
          element: "a",
          attributes: {
            href: data.url,
            target: "_blank",
            class: EDITOR_LINK,
          },
        };
      }
    },
  };

  if (clipAfterLineNumber > 0) {
    const beforeReadMore = convertToRaw(contentState);

    beforeReadMore.blocks = beforeReadMore.blocks.slice(
      0,
      +clipAfterLineNumber
    );

    beforeHTML = stateToHTML(convertFromRaw(beforeReadMore), options);

    const afterReadmore = convertToRaw(contentState);
    afterReadmore.blocks = afterReadmore.blocks.slice(+clipAfterLineNumber);
    afterHTML = stateToHTML(convertFromRaw(afterReadmore), options);
  } else {
    beforeHTML = stateToHTML(contentState, options);
    afterHTML = "";
  }

  // Return this if someone wants immediately.
  return { beforeHTML, afterHTML };
};
