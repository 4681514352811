import React, { Component } from "react";
import ContactService from "./ContactService";

export default class CreateContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      Source: "",
      PageName: "Create Contact",
      IgnoreWelcomeEmail: false,
    };
  }

  populate = (event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [key]: val });
  };

  save = async () => {
    const {
      FirstName,
      LastName,
      EmailAddress,
      Source,
      IgnoreWelcomeEmail,
    } = this.state;
    console.log("Data", FirstName, LastName, EmailAddress, Source);
    const { IsError, data, status } = await ContactService.create({
      FirstName,
      LastName,
      EmailAddress,
      Source,
      IgnoreWelcomeEmail,
    });

    if (IsError === true) {
      let errorMsg = [...this.state.errorMessages];
      errorMsg.push("Unknown error");
      this.setState({ errorMessages: errorMsg });
    } else {
      let errorMsg = [...this.state.errorMessages];
      errorMsg.push(
        status === 204
          ? `Contact may be already present. Check with dev team`
          : `Contact data saved. ${data.emailAddress} added. `
      );
      this.setState({ errorMessages: errorMsg });

      setTimeout(() => {
        this.setState({
          errorMessages: [],
          FirstName: "",
          LastName: "",
          EmailAddress: "",
          Source: "",
        });
      }, 3000);
    }
  };

  render() {
    let errorMessage = "";
    if (this.state.errorMessages.length > 0) {
      errorMessage = this.state.errorMessages.map((message, index) => {
        return (
          <div key={index} className="alert alert-success" role="alert">
            {" "}
            {message}{" "}
          </div>
        );
      });
    }

    return (
      <div className="mt-5">
        <div className="">
          <div className="col-sm-12 border p-5">
            <h3>{this.state.PageName}</h3>

            <div className="mt-5">
              {errorMessage}

              <form>
                <div className="form-row mb-3">
                  <div className="col-sm-6">
                    <label htmlFor="FirstName">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.populate}
                      name="FirstName"
                      placeholder="Enter First Name i.e. John"
                      value={this.state.FirstName}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="LastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.populate}
                      name="LastName"
                      placeholder="Enter Last Name i.e Doe"
                      value={this.state.LastName}
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col-sm-6">
                    <label htmlFor="EmailAddress">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.populate}
                      name="EmailAddress"
                      placeholder="Enter Email Address i.e. john.doe@example.com"
                      value={this.state.EmailAddress}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="Source">Source</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.populate}
                      name="Source"
                      placeholder="Enter Source i.e. facebook, twitter, referral."
                      value={this.state.Source}
                    />
                  </div>
                </div>
                {/* // Add checkbox */}

                <div className="form-group mb-3">
                  <div>
                    <label className="pr-3">Ignore Welcome Email</label>
                    <input
                      type="checkbox"
                      onChange={this.populate}
                      name="IgnoreWelcomeEmail"
                      checked={this.state.IgnoreWelcomeEmail}
                    />
                  </div>
                </div>

                <div className="form-group mb-3 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.save}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
