import React, { useState, useEffect } from "react";
import MarketDataService from "components/marketData/MarketDataService";
import { useHistory, useParams } from "react-router";
import moment from "moment";
// ------------------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { createMarketdata } from "../../redux/actions/marketData/MarketdataAction";
import { retrieveByIdMarketdata } from "../../redux/actions/marketData/MarketdataAction";
import { updateMarketdata } from "../../redux/actions/marketData/MarketdataAction";
import { PROCESS } from "redux/processConstants";

const CreateMarketData = (props) => {
  const history = useHistory();
  const params = useParams();
  const waitTime = 3000;

  const emptyTicker = {
    TickerName: "",
    Value: "",
    DaysChange: "",
    YtdChange: "",
  };

  const [textDetails, setTextDetails] = useState([]);
  const [footnote, setFootnote] = useState("");

  const [responseErrorMessage, setResponseErrorMessage] = useState("");
  const [isResponseOk, setIsResponseOk] = useState(true);
  const [pageName, setPageName] = useState("Create Market Data");
  const [submitButtonName, setSubmitButtonName] = useState("Create");

  const [publishDate, setPublishDate] = useState("");
  const [tickerDetails, setTickerDetails] = useState([emptyTicker]);
  const [marketDataId, setMarketDataId] = useState(-1);

  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  const marketdataReducer = useSelector((state) => state.MDR);
  const { MarketdataGetByIdItem, MarketdataCreateItem, process } =
    marketdataReducer;

  // console.log("Process ", process, MarketdataGetByIdItem);

  useEffect(() => {
    console.log("ID: of new data", params.id);
    if (+params.id > 0) {
      // EDIT Process.
      setPageName("Edit Market data");
      setSubmitButtonName("Update");
      setIsEdit(true);
      setMarketDataId(+params.id);
      retrivemarketDataById(+params.id);
    }
  }, []);

  useEffect(() => {
    // console.log("Redux Marketdata Get by Id", MarketdataGetByIdItem);
    if (+params.id > 0 && MarketdataGetByIdItem) {
      setPublishDate(MarketdataGetByIdItem.publishDate.substring(0, 10));

      if (
        MarketdataGetByIdItem.footnote === null ||
        MarketdataGetByIdItem.footnote === ""
      ) {
        setFootnote(getFootnote(MarketdataGetByIdItem.publishDate));
      } else {
        setFootnote(MarketdataGetByIdItem.footnote);
      }

      const tickerDetailsMap = MarketdataGetByIdItem.tickerDataSet.map(
        (item) => {
          return {
            TickerName: item.tickerName,
            Value: item.value,
            DaysChange: +item.daysChange.replace("%", ""),
            YtdChange: +item.ytdChange.replace("%", ""),
          };
        }
      );
      setTickerDetails(tickerDetailsMap);
      setTextDetails(MarketdataGetByIdItem.marketDataBullets || []);
    }
  }, [MarketdataGetByIdItem]);

  useEffect(() => {
    console.log("Market data process ", process);
    if (process === PROCESS.CREATE) {
      history.push(`/financedata/edit/${MarketdataCreateItem}`);
      setMarketDataId(MarketdataCreateItem);
      setIsEdit(true);
      setPageName("Edit Market data");
      setSubmitButtonName("Update");
      setIsResponseOk(true);
      removeAlertMessage(1000);
      setFootnote(getFootnote(publishDate));
    }

    if (process === PROCESS.UPDATE) {
      history.push("/financedata");
    }
  }, [process]);

  const retrivemarketDataById = async (id) => {
    console.log("MD Getby Id", id);
    dispatch(retrieveByIdMarketdata(id));
  };

  const getFootnote = (publishDate) => {
    return `Market data as of 01:00am, ${moment(
      publishDate.substring(0, 10)
    ).format("DD MMMM YYYY")}`;
  };

  const validate = () => {
    let allErrorMessages = [];

    if (!publishDate || publishDate.trim() === "") {
      allErrorMessages.push("Invalid published date");
    }

    for (let index = 0; index < tickerDetails.length; index++) {
      const { TickerName, Value, DaysChange, YtdChange } = tickerDetails[index];

      if (!TickerName || TickerName.trim() === "") {
        allErrorMessages.push("One of the ticker name is empty");
      }

      if (!Value) {
        allErrorMessages.push("One of the ticker value is empty");
      }
      if (!DaysChange) {
        allErrorMessages.push("One of the ticker DayChange is empty");
      }
      if (!YtdChange) {
        allErrorMessages.push("One of the ticker YearChange is empty");
      }
    }
    if (tickerDetails.length % 3 !== 0) {
      allErrorMessages.push("you should have Ticker box in multiple of 3");
    }
    return allErrorMessages;
  };

  const addTikerBox = () => {
    // console.log("Add Tiker Box Handler");
    const cloneTicker = [...tickerDetails];
    cloneTicker.push(emptyTicker);
    setTickerDetails(cloneTicker);
  };

  const fillTickerDetails = (key, value, index) => {
    // console.log("Fill Tiker details");
    const clonedTickerDetails = [...tickerDetails];
    clonedTickerDetails[index][key] = value;
    setTickerDetails(clonedTickerDetails);
  };

  const removeTikerBox = (index) => {
    // console.log("Remove Tiker Box Handler", index);
    const cloneTicker = [...tickerDetails];
    cloneTicker.splice(index, 1);
    setTickerDetails(cloneTicker);
  };

  const addTextInputBox = () => {
    // console.log("Add Text Box Handler");
    const cloneTextInput = [...textDetails];
    cloneTextInput.push("");
    setTextDetails(cloneTextInput);
  };

  const fillTextDetails = (key, value, index) => {
    // console.log("Fill Text details");
    const clonedTextDetails = [...textDetails];
    clonedTextDetails[index] = value;
    setTextDetails(clonedTextDetails);
  };

  const removeTextInputBox = (index) => {
    // console.log("Remove Tiker Box Handler", index);
    const cloneTextInput = [...textDetails];
    cloneTextInput.splice(index, 1);
    setTextDetails(cloneTextInput);
  };

  const removeAlertMessage = (timeoutInms) => {
    setTimeout(() => {
      setResponseErrorMessage("");
    }, timeoutInms);
  };

  const SaveMarketData = async () => {
    setResponseErrorMessage("");
    const apiData = { PublishDate: publishDate };

    if (isEdit === true) {
      // EDIT
      const allErrorMessages = validate();
      console.log("Validation error ", allErrorMessages.join(","));
      if (allErrorMessages.length > 0) {
        setResponseErrorMessage(allErrorMessages.join(","));
        setIsResponseOk(false);
        return;
      }

      apiData.Footnote = footnote;
      apiData.TickerDataSet = tickerDetails.map((item) => {
        return {
          TickerName: item.TickerName,
          Value: item.Value,
          DaysChange:
            item.DaysChange > 0
              ? `+${item.DaysChange}%`
              : `${item.DaysChange}%`,
          YtdChange:
            item.YtdChange > 0 ? `+${item.YtdChange}%` : `${item.YtdChange}%`,
        };
      });

      apiData.MarketDataBullets = textDetails;
      apiData.Id = marketDataId;
      dispatch(updateMarketdata(marketDataId, apiData));
    } else {
      // CREATE
      dispatch(createMarketdata(apiData));
    }
  };

  return (
    <div className="container-fluid border mt-5">
      <div className="pt-5">
        {responseErrorMessage.length > 0 && (
          <div
            className={
              isResponseOk ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {responseErrorMessage}
          </div>
        )}
      </div>
      <div className="row align-item-column">
        <div className="col-sm-12 p-5">
          <h3>{pageName}</h3>
        </div>
        <div className="col mb-3 pl-5">
          <label for="dateFilter" className="form-group mr-3">
            Publish Date
          </label>
          <input
            type="date"
            name="dateFilter"
            value={publishDate.substring(0, 10)}
            onChange={(event) => setPublishDate(event.target.value)}
          />
        </div>
      </div>

      {isEdit && (
        <div>
          {/* =========================================================== */}
          <div className="col-11 mb-5">
            <div className="">
              <label>Footnote</label>
              <br />
              <input
                type="text"
                name="BulletText"
                className="form-control"
                maxLength=""
                value={footnote}
                onChange={(e) => setFootnote(e.target.value)}
                placeholder="Source:"
              />
            </div>
          </div>
          {/* =========================================================== */}
          <div className="form-group mb-3">
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              onClick={addTikerBox}
            >
              Add Tiker
            </button>
          </div>

          {tickerDetails.map((item, index) => {
            return (
              <div className="container-fluid border mt-3 mb-5">
                <div className="row-fluid ">
                  <div className="row  justify-content-evenly  display-flex row  p-5">
                    <div className="col-3 mr-5">
                      <label htmlFor="TikerName" className="form-label">
                        Ticker Name
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        value={item.TickerName}
                        onChange={(e) =>
                          fillTickerDetails("TickerName", e.target.value, index)
                        }
                        placeholder="Ex-BSE, Nifty, Gold,. . .etc"
                      />
                    </div>
                    <div className="col-2 mr-5">
                      <label htmlFor="Value" className="form-label">
                        Value
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control"
                        value={item.Value}
                        onChange={(e) =>
                          fillTickerDetails("Value", e.target.value, index)
                        }
                        placeholder="00,000"
                      />
                    </div>
                    <div className="col-2 mr-5">
                      <label htmlFor="TikerName" className="form-label">
                        Day's Change (&#x25B2; 1-DAY)
                      </label>
                      <br />
                      <input
                        type="number"
                        className="form-control"
                        value={item.DaysChange}
                        onChange={(e) =>
                          fillTickerDetails("DaysChange", e.target.value, index)
                        }
                        placeholder="+0.00"
                      />
                      {/* &#x25; */}
                    </div>
                    <div className="col-2 mr-5">
                      <label htmlFor="TikerName" className="form-label">
                        Year To Day (YTD)
                      </label>
                      <br />
                      <input
                        type="number"
                        className="form-control"
                        value={item.YtdChange}
                        onChange={(e) =>
                          fillTickerDetails("YtdChange", e.target.value, index)
                        }
                        placeholder="-0.00"
                      />
                    </div>
                    <div className="col-1 d-flex justify-content-end mt-4 pt-2 mb-0 pr-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => removeTikerBox(index)}
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="bi bi-trash"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fillRule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* ============================================================ */}
      {isEdit && (
        <div id="">
          <div className="form-group mb-3">
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              onClick={addTextInputBox}
            >
              Add Bullet Text
            </button>
          </div>

          {textDetails.map((item, index) => {
            return (
              <div key={index} className="row-fluid border  mt-3 mb-5">
                <div className="display-flex row p-5">
                  <div className="col-11">
                    <div className="">
                      <label>Bullet Text</label>
                      <br />
                      <input
                        type="text"
                        name="BulletText"
                        className="form-control"
                        maxLength=""
                        value={item}
                        onChange={(e) =>
                          fillTextDetails("BulletText", e.target.value, index)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-1 d-flex justify-content-end mt-4 pt-2 mb-0 pr-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => removeTextInputBox(index)}
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-trash"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="col">
        <div className="col-sm-12 d-flex justify-content-end pr-5 mb-3">
          <button
            className="btn btn-primary"
            type="button"
            onClick={SaveMarketData}
            value="Submit"
          >
            {submitButtonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateMarketData;
