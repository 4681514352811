import React from "react";
import ContactPaginate from "./ContactPaginate";

const ContactTable = ({ contactsData, onPageNumberClick }) => {
  return (
    <div>
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#ID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Source</th>
          </tr>
        </thead>
        <tbody>
          {contactsData.map((contact, index) => {
            const { id, emailAddress, firstName, lastName, source } = contact;
            return (
              <tr key={index}>
                <td>{id}</td>
                <td>{[firstName, lastName].join(" ")}</td>
                <td>{emailAddress}</td>
                <td>{source}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ContactPaginate contactPageNumberChange={onPageNumberClick} />
    </div>
  );
};
export default ContactTable;
