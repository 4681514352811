import React, { Component } from "react";
import moment from "moment";

const USER_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm";

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showName: false,
    };
  }
  render() {
    const { stories } = this.props;

    return (
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="story-id">
              #
            </th>
            <th scope="col" className="story-headline">
              Headline
            </th>
            <th scope="col" className="story-category">
              Category
            </th>
            <th scope="col" className="story-image">
              Last Modified
            </th>
            <th scope="col" className="story-actions">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {stories.map((item, index) => {
            let showModification = false;
            if (item.lastModified && item.lastModified.length > 0) {
              showModification = true;

              var local = moment
                .utc(item.lastModified)
                .local()
                .format("YYYY-MMM-DD hh:mm A");
              // console.log("Local Modified Time", local);
            }

            const { filterTableData, filterCategory } = this.props;
            let searchText = filterTableData.toLowerCase();
            if (
              item.headline.toLowerCase().includes(searchText) &&
              (item.category.name == filterCategory || filterCategory == "")
            ) {
              return (
                <tr key={index}>
                  <th scope="row" className="story-id">
                    {item.id}
                  </th>
                  <td className="story-headline">{item.headline}</td>
                  <td className="story-category">{item.category.name}</td>
                  <td className="story-modified">
                    {showModification === true ? (
                      <div className="">{local}</div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="story-actions">
                    <button
                      type="button"
                      className="btn btn-outline-info mx-1"
                      onClick={(e) => this.props.editStory(item.id, e)}
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-pencil"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                        />
                      </svg>
                    </button>
                    {item.isPublished ? (
                      <a
                        href={`https://news.boringnews.co/story/${item.publishedUrl}`}
                        target="_blank"
                        className="btn btn-outline-info mx-1"
                        rel="noreferrer"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          className="bi bi-eye"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                          />
                        </svg>
                      </a>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-info mx-1"
                      onClick={(e) => this.props.uploadImage(item.id, e)}
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-cloud-upload"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    );
  }
}
