import { EDITOR_LINK } from "components/editor/DraftClassNameConstants";
import React from "react";

export const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} className={EDITOR_LINK} target="_blank">
      {props.children}
    </a>
  );
};
