import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import DeletePrompt from "components/common/DeletePrompt";
import ProgressBarService from "./ProgressBarService";
import ProgressBarTable from "./ProgressBarTable";
// -----------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { retrieveProgressbar } from "../../redux/actions/progressbar/ProgressbarAction";
import { deleteProgressbar } from "../../redux/actions/progressbar/ProgressbarAction";

const ProgressBarList = () => {
  const history = useHistory();
  const [progressData, setProgressData] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [progressDataId, setProgressDataId] = useState(-1);

  const dispatch = useDispatch();
  const progressState = useSelector((state) => state.PBR);
  const { ProgressListItem, ProgressDeleteItem, process } = progressState;

  useEffect(() => {
    console.log("UseEffect List call");
    retrieve();
  }, [ProgressDeleteItem]);

  const retrieve = async () => {
    dispatch(retrieveProgressbar());
  };

  const gotocreateProgressBarPage = (data) => {
    // console.log("Go to Create ProgressBar Page", data);
    history.push(`progressbar/create`);
    // retrieve();
  };
  const editProgressData = (progressDataId) => {
    // console.log("Edit Progress Data");
    history.push(`/progressbar/edit/${progressDataId}`);
  };
  const deleteProgressData = (progressDataId) => {
    // console.log("ProgressData Select Id for Delete", progressDataId);
    setShowDeletePrompt(true);
    setProgressDataId(progressDataId);
  };
  const onDeleteProgressDataCancel = () => {
    // console.log("Delete Canced ");
    setShowDeletePrompt(false);
  };
  const onDeleteProgressDataConfirm = async () => {
    dispatch(deleteProgressbar(progressDataId));
    setShowDeletePrompt(false);
    setProgressDataId(-1);
    retrieve();
  };

  return (
    <div>
      <DeletePrompt
        show={showDeletePrompt}
        header="Delete Progress Data"
        body="Are You Sure Want to Delete this Progress Data"
        onHide={onDeleteProgressDataCancel}
        onSuccess={onDeleteProgressDataConfirm}
      />

      <div className="container-border mt-5 p-3">
        <div className="">
          <div className="row">
            <div className="col-2">
              <h3>Progress Bars</h3>
            </div>
          </div>

          <div className="row d-flex justify-content-end ">
            <div className="mr-3 mb-3">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={gotocreateProgressBarPage}
              >
                Create Progress Bar
              </button>
            </div>
          </div>
          <ProgressBarTable
            progressDataSet={ProgressListItem}
            EditProgressData={editProgressData}
            DeleteProgressData={deleteProgressData}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBarList;
