import React, { Component } from "react";
import DraftEditor from "./DraftEditor";

export default class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonData: null,
      htmlData: null,
    };
  }
  logState = (json, html) => {
    console.log("Save in demo js");
    this.setState({ jsonData: json, htmlData: html });
  };
  saveData = () => {
    console.log("Save final data");
    console.log(this.state.jsonData, this.state.htmlData);
  };
  render() {
    return (
      <div>
        <div>
          <DraftEditor save={this.logState} />
        </div>
        <input onClick={this.saveData} type="button" value="Save" />
      </div>
    );
  }
}
