import React, { Component } from "react";
import StoryService from "components/story/StoryService";

export default class StoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],

      Headline: "",
      Category: "",
      ImageUrl: "",
      IsPublished: false,

      Contents: [],
      ID: -1,
    };
  }

  componentDidMount() {
    console.log("Story ID", this.props.match.params.id);
    const storyId = parseInt(this.props.match.params.id, 10);
    if (storyId > 0) {
      this.setState({ ID: storyId });

      StoryService.getStorieByID(storyId, (error, result) => {
        if (error) {
          console.log("Error while retirving view page");
        } else {
          console.log("Fill the data view page", result.headline);
          let contents = [];
          for (let i = 0; i < result.content.length; i++) {
            contents.push({
              Head: result.content[i].head,
              Body: result.content[i].body,
            });
          }

          this.setState({
            Headline: result.headline,
            Category: result.category.name,
            ImageUrl: result.imageUrl,
            Contents: contents,
            IsPublished: result.isPublished,
          });
        }
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <div className="container">
              <div className="row">
                <div col-sm-12>
                  <span className="badge badge-info">
                    {this.state.Category}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="font-lg">{this.state.Headline}</div>
                </div>
              </div>
              <div className="row">
                <div col-sm-12>
                  <img src={this.state.ImageUrl} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
