import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ImageService from "./ImageService";

const ImageUpload = () => {
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState(null);
  const [responseErrorMessage, setResponseErrorMessage] = useState("");
  const [isResponseOk, setIsResponseOk] = useState(true);

  const saveImage = async () => {
    console.log("Save Upload Image Function");

    var data = new FormData();
    data.append("", selectedImage);

    if (selectedImage !== null && name?.length > 0) {
      let key =
        name + " " + selectedImage.name + " " + selectedImage.lastModifiedDate;

      const { response, success } = await ImageService.uploadImage(
        data,
        key,
        name
      );

      history.push(`/gallery`);
      setIsResponseOk(true);
      setResponseErrorMessage("Your Data Uploaded successfully");
      removeAlertMessage(3000);
    } else {
      setResponseErrorMessage("You don't Selected Name or fileName");
      setIsResponseOk(false);
      removeAlertMessage(5000);
    }
  };

  const removeAlertMessage = (timeoutInms) => {
    setTimeout(() => {
      setResponseErrorMessage("");
    }, timeoutInms);
  };

  return (
    <div className="container-fluid border mt-5 p-5">
      <div className="pt-5">
        {responseErrorMessage.length > 0 && (
          <div
            className={
              isResponseOk ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {responseErrorMessage}
          </div>
        )}
      </div>

      <div className="">
        <h3>Upload Image</h3>
      </div>

      <div className="row d-flex justify-content-center mb-3 ">
        <div className="col-4  ">
          <div className="col">Name</div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            ></input>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="col-4 card border">
          <div className="row d-flex justify-content-center bg-warning">
            <input
              type="file"
              name="myImage"
              onChange={(event) => {
                console.log("File Name" + event.target.value);
                setSelectedImage(event.target.files[0]);
              }}
            />
          </div>

          {selectedImage && (
            <div>
              <div className="row d-flex justify-content-end bg-danger">
                <button
                  onClick={() => {
                    setSelectedImage(null);
                  }}
                >
                  X
                </button>
              </div>

              <div className="row d-flex justify-content-center bg-dark">
                <img
                  alt="not fount"
                  width={"100%"}
                  src={URL.createObjectURL(selectedImage)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row d-flex justify-content-center mt-2">
        <span>Image should be lessthan 5MB.</span>
      </div>

      <div className="col mt-5">
        <div className="col-sm-12 d-flex justify-content-center pr-5">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={saveImage}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
