import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";

class StoryService {
  create(story, cb) {
    axios
      .post(`${Auth.getBaseURL()}/Stories`, story)
      .then((res) => {
        console.log("Saved", res);
        cb(false, res.data);
      })
      .catch((error) => {
        console.log("Error while saving story", error.response.data);
        let messages = [];
        Object.keys(error.response.data.errors).forEach((item) => {
          messages.push(error.response.data.errors[item][0]);
        });
        console.log(messages.join(","));
        cb(true, messages);
      });
  }

  async createAsync(story) {
    try {
      const apiResponse = await axios.post(
        `${Auth.getBaseURL()}/Stories`,
        story
      );
      return HttpResponseHandler.returnSuccess(apiResponse);
    } catch (error) {
      return HttpResponseHandler.returnError(error);
    }
  }

  edit(story, cb) {
    axios
      .put(`${Auth.getBaseURL()}/Stories/${story.Id}`, story)
      .then((res) => {
        console.log("Saved", res);
        cb(false, res.data);
      })
      .catch((error) => {
        console.log("Error response while saving story", error.response);
        let messages = [];
        Object.keys(error.response.data.errors).forEach((item) => {
          messages.push(error.response.data.errors[item][0]);
        });
        console.log(messages.join(","));
        cb(true, messages);
      });
  }

  async editAsync(story) {
    try {
      const apiResponse = await axios.put(
        `${Auth.getBaseURL()}/Stories/${story.Id}`,
        story
      );

      return HttpResponseHandler.returnSuccess(apiResponse);
    } catch (error) {
      return HttpResponseHandler.returnError(error);
    }
  }

  getToken() {
    return this.token;
  }

  getCategories(cb) {
    axios
      .get(`${Auth.getBaseURL()}/Categories`)
      .then((res) => {
        console.log("Category", res.data);
        cb(false, res.data);
      })
      .catch((error) => {
        console.log("Error while retiriving categories for story ", error);
        let messages = ["There was error getting the categories."];

        cb(true, messages);
      });
  }

  async getCategoriesAsync() {
    try {
      const apiResponse = await axios.get(`${Auth.getBaseURL()}/Categories`);
      return { data: apiResponse.data, status: true };
    } catch (error) {
      return { data: [], status: false };
    }
  }

  getStories(dateFilter, categoryId, cb) {
    let filter = `?Date=${dateFilter}`;

    if (categoryId != "-1") {
      filter += `&categoryId=${categoryId}`;
    }

    axios
      .get(`${Auth.getBaseURL()}/Stories${filter}`)
      .then((res) => {
        console.log("Stories", res.data);
        cb(false, res.data);
      })
      .catch((error) => {
        console.log("Error while retiriving Stories for story ", error);
        let messages = [];

        cb(true, messages);
      });
  }

  // includeAdditionalCategories is an optional paramter.
  // If you're not sending this will not be sent to server.

  async getStoriesAsync(dateFilter, categoryId, includeAdditionalCategories) {
    console.log("retrieve story by date and category");
    try {
      let filter = `?Date=${dateFilter}`;

      if (categoryId != "-1") {
        filter += `&categoryId=${categoryId}`;
      }
      // IncludeAdditionalCategories
      if (typeof includeAdditionalCategories !== "undefined") {
        filter += `&includeAdditionalCategories=${includeAdditionalCategories}`;
        console.log("Additional story by date filter2222");
      }
      const apiResponse = await axios.get(
        `${Auth.getBaseURL()}/Stories${filter}`
      );

      return apiResponse.data;
    } catch (error) {}
  }

  getStorieByID(id, cb) {
    axios
      .get(`${Auth.getBaseURL()}/Stories/${id}`)
      .then((res) => {
        console.log("Story", res.data);
        cb(false, res.data);
      })
      .catch((error) => {
        console.log("Error while retiriving Story by id for story ", error);
        let messages = [];

        cb(true, messages);
      });
  }

  async getStorieByIDAsync(id) {
    try {
      const apiResponse = await axios.get(`${Auth.getBaseURL()}/Stories/${id}`);
      return HttpResponseHandler.returnSuccess(apiResponse);
    } catch (error) {
      return HttpResponseHandler.returnError(error);
    }
  }

  getDefaultDate() {
    let today = new Date();
    if (today.getHours() > 8) {
      today.setDate(today.getDate() + 1);
    }

    return today.toJSON().slice(0, 10);
  }
}

export default new StoryService();
