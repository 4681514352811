import React, { Component } from "react";
import Auth from "components/auth/Auth";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    if (Auth.isAuthenticated()) {
      this.props.history.push("/story");
    }
  }

  populate = (event) => {
    let key = event.target.name;
    let val = event.target.value;
    this.setState({ [key]: val });
  };

  authenticate = () => {
    const email = this.state.email;
    const password = this.state.password;
    Auth.login(email, password, (error, message) => {
      if (error) {
        this.setState({ errorMessage: message });
      } else {
        this.props.history.push("/story");
      }
    });
  };

  render() {
    let errorMessage;
    if (this.state.errorMessage.length > 0) {
      errorMessage = (
        <div className="alert alert-danger" role="alert">
          {" "}
          {this.state.errorMessage}{" "}
        </div>
      );
    }
    return (
      <div className="mt-5">
        <div className="d-flex justify-content-center">
          <div className="col-sm-5 border p-5">
            <h3>Login Page</h3>

            <div className="mt-5">
              {errorMessage}

              <form>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={this.populate}
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.populate}
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.authenticate}
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
