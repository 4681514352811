import FileUpload from "components/file_upload/FileUpload";
import React, { Component } from "react";
import NewsLetterService from "./NewsLetterService";

class NewsLetterImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: -1,
      sections: [],
    };
  }
  componentDidMount() {
    const newsLetterId = parseInt(this.props.match.params.id, 10);

    // Get the newsletter details
    if (newsLetterId > 0) {
      // Edit process.
      this.setState({
        ID: newsLetterId,
      });

      NewsLetterService.getById(newsLetterId, (error, data) => {
        if (error) {
          console.log("[Upload] - Error while getting newsletter data");
        } else {
          // set the data
          let newsLetterData = data.newsletterSections.map((item) => {
            

            return {
              ID: item.id,
              Name: this.getSectionNameFromImage(item.storyImageUrl),
            };
          });
          console.log("Sections", newsLetterData);
          this.setState({
            sections: newsLetterData,
          });
        }
      });
    }
  }

  getSectionNameFromImage=(imageName)=>{
      let name = '';
      if(imageName === null || imageName===undefined){
          return name;
      }
      let nameExtract = imageName.split("/").pop();
      let allNames = nameExtract.split('-');

      for(let i=0; i<allNames.length; i++){
          if(!(/\d/.test(allNames[i]))){
            name += allNames[i] + ' ';
          }
      }
      return name.replace('.jpg','').trim().toUpperCase();
  }

  render() {
    return (
      <div className="mt-5">
        {this.state.sections.map((item, index) => {
          return (
            <div key={item.ID} className="border my-5">
              <div className="col-sm-12 p-2">{item.Name}</div>
              <div className="col-sm-12 p-2">
                <FileUpload fileUploadType="newsletter" newsletterSectionId={item.ID} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default NewsLetterImageUpload;
