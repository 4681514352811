import React, { Component } from "react";
import Table from "components/common/Table";
import StoryService from "components/story/StoryService";
import Helper from "components/common/Helper";
import StoryImageUpload from "./StoryImageUpload";

export default class StoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Stories: [],
      categories: [],
      dateFilter: "",
      categoryValue: -1,
      showModal: false,
      storyId: -1,
      searchData: "",
      filterCategory: "",
    };
  }
  componentDidMount() {
    let today = Helper.getDate();
    console.log("Current day", today);
    this.setState({ dateFilter: today }, this.fillCategories);
  }

  refreshStories = (selectedDate, categoryId) => {
    StoryService.getStories(selectedDate, categoryId, (error, result) => {
      if (error) {
        console.log("error while getting stories");
      } else {
        this.setState({ Stories: result });
        let demoCatergory = [];
        for (let i = 0; i < result.length; i++) {
          demoCatergory.push(result[i].category.name);
        }
        let set = new Set(demoCatergory);
        demoCatergory = [...set];
        this.setState({ categories: demoCatergory });
        console.log(this.state.categories);
      }
    });
  };

  filterChange = (e) => {
    console.log("Target name", e.target.name);
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      const { dateFilter, categoryValue, searchData } = this.state;
      this.refreshStories(dateFilter, categoryValue, searchData);
    });
  };

  fillCategories = () => {
    StoryService.getCategories((error, result) => {
      if (error) {
        console.log("error while getting categories");
      } else {
        this.setState({ categories: result }, () => {
          const { dateFilter, categoryValue } = this.state;
          this.refreshStories(dateFilter, categoryValue);
        });
      }
    });
  };

  filterSearchText = (serchValue) => {
    // console.log("Filter Search Text", serchValue);
    this.setState({
      searchData: serchValue,
    });
  };

  editStory = (id, event) => {
    console.log("editing", id);
    this.props.history.push(`/story/story-create-new/${id}`);
  };

  viewStory = (id, event) => {
    this.props.history.push(`/story/view/${id}`);
  };

  createStory = () => {
    this.props.history.push(`/story/story-create-new`);
  };

  uploadImage = (id, event) => {
    console.log("Opening Image upload modal");
    this.setState({
      storyId: id,
      showModal: true,
    });
  };
  filterCategory = () => {
    let filteredCategory = document.getElementById("abc").value;
    console.log("Filtering the data", filteredCategory);
    this.setState({ filterCategory: filteredCategory });
  };
  render() {
    return (
      <div className="my-5">
        <div className="row">
          <div className="col-sm-12 my-2">
            <h4>Stories</h4>
          </div>
          <div className="col-sm-12 my-2">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={this.createStory}
              >
                Create Story
              </button>
            </div>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-sm-3 d-flex flex-row">
            <label htmlFor="dateFilter" className="">
              Story date
            </label>
            <div className="col-6">
              <input
                class="form-control"
                type="date"
                name="dateFilter"
                value={this.state.dateFilter}
                onChange={this.filterChange}
              />
            </div>
          </div>

          <div className="col-sm-2 d-flex flex-row mr-5">
            <label htmlFor="categoryValue" className="mr-3">
              Category
            </label>
            <select
              class="form-control"
              value={this.state.categoryId}
              name="categoryValue"
              id="abc"
              onChange={this.filterCategory}
            >
              <option value="">All</option>
              {this.state.categories.sort().map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {String(item)}
                  </option>
                );
              })}
            </select>
          </div>
          {/* ------------------------------------------------------------------------------------------------------ */}
          <div className="col-sm-5 ml-5">
            <div class="input-group">
              <div class="col-sm-6 d-flex flex-row">
                <input
                  type="search"
                  placeholder="Search..."
                  id="form1"
                  class="form-control"
                  name="searchData"
                  onChange={(e) => this.filterSearchText(e.target.value)}
                ></input>
                <button
                  type="button"
                  class="btn btn-primary ml-1"
                  // onClick={(e) => this.filterSearchText(e.target.value)}
                >
                  <i class="bi bi-search"></i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-3"></div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------- */}
        <Table
          filterCategory={this.state.filterCategory}
          filterTableData={this.state.searchData}
          stories={this.state.Stories}
          viewStory={this.viewStory}
          editStory={this.editStory}
          uploadImage={this.uploadImage}
        />
        <StoryImageUpload
          storyid={this.state.storyId}
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        />
      </div>
    );
  }
}
