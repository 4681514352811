import axios from "axios";
import Auth from "components/auth/Auth";
import HttpResponseHandler from "components/common/HttpResponseHandler";

class ImageService {
  //==========================(Post)========================================

  async uploadImage(data, key, name) {
    try {
      console.log("API Post Request", data, key, name);

      const response = await axios.post(
        `${Auth.getBaseURL()}/LibraryImage?key=${key}&name=${name}&file=${data}`,
        data
      );

      console.log("API Post Response", response);

      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("API Post Error Response", error);
      return HttpResponseHandler.returnError(error.response);
    }
  }

  //==============================(Get / Retrieve)======================================

  async retrieve(index, size) {
    try {
      console.log("API Get Request");
      const response = await axios.get(
        `${Auth.getBaseURL()}/LibraryImage?index=${index}&size=${size}`
      );

      console.log("API Service Get Response", response);

      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("API Get Error Response");
      return HttpResponseHandler.returnError(error.response);
    }
  }

  //=============================(Get by Id)===================================

  async retrieveById(imageId) {
    try {
      console.log("API Get By ID Request");

      const response = await axios.get(
        `${Auth.getBaseURL()}/LibraryImage/${imageId}`
      );

      console.log("API Get By ID Response", response);

      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("API Get By ID Error Response");
      return HttpResponseHandler.returnError(error.response);
    }
  }

  //==============================(Update/Edit)=====================================

  async updateImage(id, data, name) {
    try {
      console.log("API Update/edit Request");

      const response = await axios.put(
        `${Auth.getBaseURL()}/LibraryImage/${id}?name=${name}`,
        data
      );

      console.log("API Update/edit Response", response);

      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("API Update/edit Error Response");
      return HttpResponseHandler.returnError(error.response);
    }
  }

  //==============================(Delete)=====================================

  async deleteImage(id) {
    try {
      console.log("API Delete Request");

      const response = await axios.delete(
        `${Auth.getBaseURL()}/LibraryImage/${id}`
      );

      console.log("API Delete Response", response);

      return HttpResponseHandler.returnSuccess(response);
    } catch (error) {
      console.log("API Delete Error Response");
      return HttpResponseHandler.returnError(error.response);
    }
  }
}
export default new ImageService();
