import React, { Component } from "react";
import StoryContent from "components/story/StoryContent";
import StoryService from "components/story/StoryService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { PageMask } from "components/common/PageMask";

const animatedComponents = makeAnimated();

export default class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],

      Headline: "",
      Category: "",
      ImageUrl: "",
      ImageBy: "",
      PublishedURL: "",
      IsPublished: false,
      AdditionalCategories: [],

      Contents: [],
      categoriesSelect: [],
      multiSelectCategory: [],
      PageName: "Create Story",
      Process: "create",
      ID: -1,
      PublishedDate: "",

      ReadMoreOptions: [],
      ClipAfterLineNumber: -1,
      IsLoading: false,
    };
  }

  async componentDidMount() {
    // Fill the categories first.
    const { data, status } = await StoryService.getCategoriesAsync();

    if (status === false) {
      this.setState({
        errorMessages: [
          "There was an error couured while retrieving the categories",
        ],
      });
    } else {
      this.setState({
        categoriesSelect: data,
        multiSelectCategory: data.map((item) => {
          return { value: item.id, label: item.name };
        }),
      });
    }

    console.log("ID from params", this.props.match.params.id);
    const storyId = parseInt(this.props.match.params.id, 10);
    if (storyId > 0) {
      this.setState({ PageName: "Edit Story", Process: "Edit", ID: storyId });

      StoryService.getStorieByID(storyId, (error, result) => {
        if (error) {
          console.log("Error while editing");
        } else {
          this.removeSelectedCategory(result.category.id);
          let contents = [];
          for (let i = 0; i < result.content.length; i++) {
            contents.push({
              Head: result.content[i].head,
              Body: result.content[i].body,
              IsSummary: result.content[i].isSummary,
            });
          }
          let contentOptions = this.getReadMoreOptions(contents);
          const additionalCategories = result.storyCategories.map((item) => {
            let selectedOne = this.state.multiSelectCategory.filter(
              (multiselectCat) => {
                return multiselectCat.value === item.categoryId;
              }
            );
            if (selectedOne.length > 0) {
              return {
                label: selectedOne[0].label,
                value: selectedOne[0].value,
              };
            }
          });

          this.setState({
            Headline: result.headline,
            Category: result.category.id,
            ImageUrl: result.imageUrl,
            ImageBy: result.imageBy,
            Contents: contents,
            IsPublished: result.isPublished,
            PublishedURL: result.publishedUrl,
            PublishedDate: result.publishDate.slice(0, 10),
            ReadMoreOptions: contentOptions,
            ClipAfterLineNumber: result.clipAfterLineNumber,
            AdditionalCategories: additionalCategories,
          });
        }
      });
    }
  }

  populate = (event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [key]: val });
  };

  updateQuotes = (input) => {
    if (input && input.length > 0) {
      return input
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"');
    }
    return input;
  };

  childPopulate = (index, event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    this.state.Contents[index][key] = this.updateQuotes(val);

    this.setState({ Contents: [...this.state.Contents] });
  };

  multiselectPopulate = (selectedOptions) => {
    this.setState({ AdditionalCategories: selectedOptions });
  };

  removeSelectedOptionFromAdditionalCategory = (event) => {
    const selectedCategory = event.target.value;
    this.removeSelectedCategory(parseInt(selectedCategory, 10));
  };

  removeSelectedCategory = (selectedCategory) => {
    const updatedMultiSelectCategory = this.state.multiSelectCategory.filter(
      (item) => item.value !== selectedCategory
    );

    const updatedAdditionalCategories = this.state.AdditionalCategories.filter(
      (item) => item.value !== selectedCategory
    );

    console.log("updated multiple cat", updatedMultiSelectCategory);
    this.setState({
      multiSelectCategory: updatedMultiSelectCategory,
      AdditionalCategories: updatedAdditionalCategories,
    });
  };

  save = () => {
    let data = {
      Headline: this.updateQuotes(this.state.Headline),
      CategoryId: parseInt(this.state.Category, 10),
      IsPublished: this.state.IsPublished,
      Content: this.state.Contents,
      PublishDate: this.state.PublishedDate,
      ImageBy: this.state.ImageBy,
      ClipAfterLineNumber: parseInt(this.state.ClipAfterLineNumber, 10),
      AdditionalCategories: this.state.AdditionalCategories.map((item) => {
        return parseInt(item.value, 10);
      }),
    };
    this.setState({ errorMessages: [], IsLoading: true });

    if (this.state.Process === "Edit") {
      data.Id = this.state.ID;

      StoryService.edit(data, (error, message) => {
        if (error) {
          this.setState({ errorMessages: message, IsLoading: false });
        } else {
          this.props.history.push("/story");
        }
      });
    } else {
      StoryService.create(data, (error, message) => {
        if (error) {
          this.setState({ errorMessages: message, IsLoading: false });
        } else {
          this.props.history.push("/story");
        }
      });
    }
  };

  addContent = (event) => {
    const contents = [...this.state.Contents, { Head: ".", Body: "" }];
    const opt = this.getReadMoreOptions(contents);
    const clipAfterLineValue = contents.length;
    this.setState({
      Contents: contents,
      ReadMoreOptions: opt,
      ClipAfterLineNumber: clipAfterLineValue,
    });
  };

  removeContent = (index) => {
    let copyContent = [...this.state.Contents];
    copyContent.splice(index, 1);
    const opt = this.getReadMoreOptions(copyContent);
    const clipAfterLineValue = copyContent.length;
    this.setState({
      Contents: copyContent,
      ReadMoreOptions: opt,
      ClipAfterLineNumber: clipAfterLineValue,
    });
  };

  addBelowThis = (index) => {
    const insertAtPosition = index + 1;
    console.log("inserting new line after ", insertAtPosition);
    if (insertAtPosition > 0) {
      let copyContent = [...this.state.Contents];
      copyContent.splice(insertAtPosition, 0, { Head: ".", Body: "" });
      const opt = this.getReadMoreOptions(copyContent);
      const clipAfterLineValue = copyContent.length;
      this.setState({
        Contents: copyContent,
        ReadMoreOptions: opt,
        ClipAfterLineNumber: clipAfterLineValue,
      });
    }
  };

  getReadMoreOptions = (contents) => {
    let options = [];

    for (let i = 0; i < contents.length - 1; i++) {
      options.push({ value: i + 1, text: i + 1 });
    }
    options.push({ value: contents.length, text: "Hide Go deeper" });
    return options;
  };

  render() {
    let errorMessage = "";
    if (this.state.errorMessages.length > 0) {
      errorMessage = this.state.errorMessages.map((message, index) => {
        return (
          <div key={index} className="alert alert-danger" role="alert">
            {" "}
            {message}{" "}
          </div>
        );
      });
    }
    return (
      <div className="mt-5">
        {this.state.IsLoading && <PageMask />}

        <div className="">
          <div className="col-sm-12 border p-5">
            <h3>{this.state.PageName}</h3>
            <div className="mt-5">
              {errorMessage}

              <form>
                <div className="form-row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="PublishedDate">Published Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={this.populate}
                      name="PublishedDate"
                      placeholder="Enter PublishedDate"
                      value={this.state.PublishedDate}
                    />
                  </div>
                  <div className="col-sm-9"></div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="headline">Headline</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.populate}
                    name="Headline"
                    placeholder="Enter headline"
                    value={this.state.Headline}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="imageBy">Image By</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.populate}
                    name="ImageBy"
                    placeholder="Illustration source: John doe"
                    value={this.state.ImageBy}
                  />
                </div>

                <div className="form-row mb-3">
                  <div className="col-sm-2">
                    <label htmlFor="Category">Category</label>
                    <select
                      name="Category"
                      className="form-control"
                      onChange={(e) => {
                        this.populate(e);
                        this.removeSelectedOptionFromAdditionalCategory(e);
                      }}
                      value={this.state.Category}
                    >
                      <option value="-1">Select</option>
                      {this.state.categoriesSelect.map((cat, index) => {
                        return (
                          <option key={index} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-10"></div>
                </div>

                <div className="form-row mb-3">
                  <div className="col-sm-5">
                    <label htmlFor="AdditionalCategories">
                      Additional categories
                    </label>

                    <Select
                      value={this.state.AdditionalCategories}
                      onChange={this.multiselectPopulate}
                      options={this.state.multiSelectCategory}
                      isMulti
                      components={animatedComponents}
                      closeMenuOnSelect={false}
                    />
                  </div>
                  <div className="col-sm-7"></div>
                </div>

                <div className="form-group mb-3">
                  <div>
                    <label className="pr-3">Publish?</label>
                    <input
                      type="checkbox"
                      onChange={this.populate}
                      name="IsPublished"
                      checked={this.state.IsPublished}
                    />
                  </div>
                </div>

                <div className="form-row mb-3">
                  <div className="col-sm-2">
                    <label htmlFor="ClipAfterLineNumber">
                      Read more after line number
                    </label>
                    <select
                      name="ClipAfterLineNumber"
                      className="form-control"
                      onChange={this.populate}
                      value={this.state.ClipAfterLineNumber}
                    >
                      <option value="-1">Select</option>
                      {this.state.ReadMoreOptions.map((c, index) => {
                        return (
                          <option key={index} value={c.value}>
                            {c.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-10"></div>
                </div>

                <div className="form-group mb-3">
                  <button
                    className="btn btn-secondary btn-sm"
                    type="button"
                    onClick={this.addContent}
                  >
                    Add Content
                  </button>
                </div>

                {this.state.Contents.map((content, idx) => {
                  return (
                    <StoryContent
                      key={idx}
                      content={content}
                      index={idx}
                      populate={this.childPopulate}
                      removeContent={this.removeContent}
                      addBelowThis={this.addBelowThis}
                    />
                  );
                })}

                <div className="form-group mb-3 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.save}
                  >
                    Save Story
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
