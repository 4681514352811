import React, { useEffect, useState } from "react";
import DNDChild from "./DNDChild";

function DragAndDrop() {
  const [emails, setEmails] = useState([]);
  const [dragItemNumber, setDragItemNumber] = useState(-1);
  const [isDroppable, setIsDroppable] = useState(false);

  useEffect(() => {
    setEmails([
      "kaushik@boringnews.co",
      "akash@boringnews.co",
      "ritwik@boringnews.co",
      "satyendra@boringnews.co",
      "sajal@boringnews.co",
      "thomas@boringnews.co",
    ]);
  }, []);
  const handleDrag = (e) => {
    console.log("Handle drag", e.currentTarget.id);
    setDragItemNumber(e.currentTarget.id);
  };
  const handleDrop = (ev) => {
    console.log("Handle drop", dragItemNumber, ev.currentTarget.id);

    if (dragItemNumber === ev.currentTarget.id) {
      return;
    }

    let clonnedEmails = [...emails];
    const emailToInsert = emails[dragItemNumber];

    // remove item from array
    clonnedEmails.splice(dragItemNumber, 1);

    clonnedEmails.splice(ev.currentTarget.id, 0, emailToInsert);
    console.log("New order", clonnedEmails);
    setEmails(clonnedEmails);
    setDragItemNumber(-1);
    setIsDroppable(false);
  };
  const handleDragOver = (ev) => {
    ev.preventDefault();
    setIsDroppable(true);
    console.log("Test");
  };
  return (
    <div>
      {emails.map((item, index) => {
        return (
          <DNDChild
            currentIndex={index}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            isDroppable={isDroppable}
            handleDragOver={handleDragOver}
            key={index}
            email={item}
          />
        );
      })}
    </div>
  );
}

export default DragAndDrop;
