import React, { Component } from "react";

export default class StoryContent extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="Head">Head</label>
              <textarea
                type="textarea"
                rows="3"
                className="form-control"
                onChange={(e) => this.props.populate(this.props.index, e)}
                name="Head"
                placeholder="Enter Head"
                value={this.props.content.Head}
              ></textarea>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="form-group">
              <label htmlFor="Body">Body</label>
              <textarea
                type="textarea"
                rows="3"
                className="form-control"
                onChange={(e) => this.props.populate(this.props.index, e)}
                name="Body"
                placeholder="Enter Body"
                value={this.props.content.Body}
              ></textarea>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="form-group">
              <label htmlFor="remove-section">Action</label> <br />
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-2"
                onClick={() => this.props.addBelowThis(this.props.index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={() => this.props.removeContent(this.props.index)}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-trash"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
