import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import CategoryTable from "./CategoryTable";
import CategoryService from "components/category/CategoryService";
import DeletePrompt from "components/common/DeletePrompt";
// -------------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { retrieveCategory } from "../../redux/actions/category/CategoryAction";
import { deletecategory } from "../../redux/actions/category/CategoryAction";
import { getByIdCategory } from "../../redux/actions/category/CategoryAction";

const CategoryList = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [categoryID, setCategoryID] = useState(-1);

  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.CAT);
  const { CategoryListData, categoryDeleteData } = categoryState;

  useEffect(() => {
    getAllCategories();
  }, [categoryDeleteData]);

  const getAllCategories = async () => {
    console.log("Category Retrieve In List");
    dispatch(retrieveCategory());
  };

  const createCategory = (data) => {
    console.log("Go to Create Category Page", data);
    history.push(`/category/create`);
    setCategories(data);
  };

  const editCategory = (categoryID) => {
    console.log("Edit Category ");
    dispatch(getByIdCategory(categoryID));
    history.push(`/category/edit/${categoryID}`);
  };

  const deleteCategory = (categoryID) => {
    console.log("Delete Category Data", categoryID);
    setShowDeletePrompt(true);
    setCategoryID(categoryID);
    console.log(categoryID);
  };

  const deleteCategoryConfirm = async () => {
    dispatch(deletecategory(categoryID));
    getAllCategories();
    setShowDeletePrompt(false);
    setCategoryID(-1);
  };

  const onDeleteCancel = () => {
    console.log("Delete Cancel ");
    setShowDeletePrompt(false);
  };

  return (
    <>
      <div className="my-5">
        <DeletePrompt
          show={showDeletePrompt}
          header="Delete Category"
          body="Are you sure want to delete this category"
          onHide={onDeleteCancel}
          onSuccess={deleteCategoryConfirm}
        />
        <div className="row">
          <div className="col-sm-12 my-2">
            <h4>Categories</h4>
          </div>
          <div className="col-sm-12 my-2">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={createCategory}
              >
                Create Category
              </button>
            </div>
          </div>
        </div>
        <CategoryTable
          Categories={CategoryListData}
          EditCategory={editCategory}
          DeleteCategory={deleteCategory}
        />
      </div>
    </>
  );
};

export default CategoryList;
