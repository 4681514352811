import React, { Component } from "react";
import NewsLetterWhatElseStory from "./NewsLetterWhatElseStory";

export default class NewsLetterStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewsletterSectionBullets: props.story.NewsletterSectionBullets || [],
      workoutUrl: [],
      workoutText: [],
    };
  }

  storyWhatElsePopulate = (index, event) => {
    let key = event.target.name;
    let val =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    this.state.NewsletterSectionBullets[index][key] = val;

    this.setState({
      NewsletterSectionBullets: [...this.state.NewsletterSectionBullets],
    });
    this.props.setStoryWhatElse(
      this.props.index,
      this.state.NewsletterSectionBullets
    );
  };

  addNewsLetterWhatElse = () => {
    this.setState({
      NewsletterSectionBullets: [
        ...this.state.NewsletterSectionBullets,
        { StoryId: -1 },
      ],
    });
  };

  removeWhatElse = (index, event) => {
    console.log("Reoving this what else", index);
    let copyWhatElse = [...this.state.NewsletterSectionBullets];
    copyWhatElse.splice(index, 1);
    this.setState({ NewsletterSectionBullets: copyWhatElse });
    this.props.setStoryWhatElse(this.props.index, copyWhatElse);
  };

  render() {
    return (
      <div className="">
        <div className="mb-4">
          <div
            className="col-sm-12 border rounded p-3 draggable"
            id={this.props.index}
            draggable
            onDragOver={this.props.handleDragOver}
            onDragStart={this.props.handleDrag}
            onDrop={this.props.handleDrop}
          >
            {/* =================================(Add-Input-Field for News latter Add story)====================================================== */}
            <div className="form-row mb-1">
              <div className="col-sm-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => this.props.removeStory(this.props.index)}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-trash"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="form-row mb-3">
              <div className="col-sm-3">
                <label htmlFor="CategoryId">Category</label>
                <select
                  name="CategoryId"
                  className="form-control"
                  onChange={(e) => this.props.populate(this.props.index, e)}
                  value={this.props.story.CategoryId}
                >
                  <option value="-1">Select</option>
                  {this.props.categoriesSelect.map((cat, index) => {
                    return (
                      <option key={index} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <label htmlFor="StoryId">Select Story</label>
                <select
                  name="StoryId"
                  className="form-control"
                  onChange={(e) => this.props.populate(this.props.index, e)}
                  value={this.props.story.StoryId}
                >
                  <option value="-1">Select</option>
                  {this.props.storiesSelect.map((story, index) => {
                    return (
                      <option key={index} value={story.id}>
                        {story.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-3"></div>
            </div>

            {/* <div className="form-group mb-3">
              <button
                className="btn btn-secondary btn-sm"
                type="button"
                onClick={(e) => this.addNewsLetterWhatElse(this.props.index, e)}
              >
                Add What else
              </button>
            </div> */}

            {this.state.NewsletterSectionBullets.map((whatElseStory, idx) => {
              return (
                <NewsLetterWhatElseStory
                  key={idx}
                  whatElseStory={whatElseStory}
                  index={idx}
                  populate={this.storyWhatElsePopulate}
                  storiesSelect={this.props.storiesSelect}
                  removeWhatElse={this.removeWhatElse}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
