import axios from "axios";
import Auth from "components/auth/Auth";
import ErrorParser from "components/common/Parser/ErrorParser";

class FileUploadService {
  upload(id, file, uploadProgress, cb) {
    axios
      .post(`${Auth.getBaseURL()}/StoryImages/${id}`, file, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: uploadProgress,
      })
      .then((res) => {
        console.log("Saved", res);
        cb(false, res.data);
      })
      .catch((error) => {
        const message = ErrorParser.parseErrorMessage(error, "File upload");
        cb(true, message);
      });
  }

  uploadNewsletterImages(id, file, uploadProgress, cb) {
    axios
      .post(`${Auth.getBaseURL()}/NewsletterSectionImages/${id}`, file, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: uploadProgress,
      })
      .then((res) => {
        console.log("[Newsletter] - Saved", res);
        cb(false, res.data);
      })
      .catch((error) => {
        const message = ErrorParser.parseErrorMessage(error, "[Newsletter] - File upload");
        cb(true, message);
      });
  }

  uploadNewsletterHTML(dateOfNewsletter, makeIndex, file, uploadProgress, cb) {
    axios
      .post(`${Auth.getBaseURL()}/Newsletters/UploadHTML/${dateOfNewsletter}/${makeIndex}`, file , {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: uploadProgress,
      })
      .then((res) => {
        console.log("[Newsletter] - File upload Saved", res);
        cb(false, res.data);
      })
      .catch((error) => {
        const message = ErrorParser.parseErrorMessage(error, "[Newsletter] - File upload");
        cb(true, message);
      });
  }

}

export default new FileUploadService();
