import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import ProgressBarService from "./ProgressBarService";
// --------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import { createProgressbar } from "../../redux/actions/progressbar/ProgressbarAction";
import { retrieveByIdProgressbar } from "../../redux/actions/progressbar/ProgressbarAction";
import { updateProgressbar } from "../../redux/actions/progressbar/ProgressbarAction";
import { PROCESS } from "redux/processConstants";

const CreateProgressBar = () => {
  const history = useHistory();
  const params = useParams();

  const [isResponseOk, setIsResponseOk] = useState(true);
  const [responseErrorMessage, setResponseErrorMessage] = useState("");
  const [pageName, setPageName] = useState("Create Progress Bar");

  const userSection = { workoutName: "", workoutUrl: "", orderNumber: 0 };
  const [workoutUser, setworkoutUser] = useState("");

  const [progressDetails, setProgressDetails] = useState([userSection]);

  const [progressDataId, setProgressDataId] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const createState = useSelector((state) => state.PBR);
  const { progressGetById, process } = createState;

  useEffect(() => {
    console.log("UseEffect Call", params.id, typeof +params.id);
    if (+params.id > 0) {
      setPageName("Edit Progress Data");
      setIsEdit(true);
      setProgressDataId(+params.id);
      retrieveProgressDatabyID(+params.id);
    }
  }, []);

  useEffect(() => {
    if (progressDataId > 0) {
      console.log("Progressbar get by Id", progressDataId);
      setworkoutUser(progressGetById.name);

      const opions = progressGetById.sectionList.map((item) => {
        return {
          workoutName: item.name,
          workoutUrl: item.url,
          orderNumber: item.orderNumber,
        };
      });
      setProgressDetails(opions);
    }
  }, [progressGetById]);

  useEffect(() => {
    console.log("current progress process name", process);
    if (process === PROCESS.CREATE || process == PROCESS.UPDATE) {
      history.push("/progressbar");
    }
  }, [process]);

  const retrieveProgressDatabyID = async (id) => {
    dispatch(retrieveByIdProgressbar(id));
  };

  const addContentBox = () => {
    const cloneprogressdata = [...progressDetails];
    cloneprogressdata.push(userSection);
    setProgressDetails(cloneprogressdata);
  };

  const setProgressData = (key, value, index) => {
    const cloneprogressdata = [...progressDetails];
    cloneprogressdata[index][key] = value;
    setProgressDetails(cloneprogressdata);
  };

  const removeContentBox = () => {
    const cloneprogressdata = [...progressDetails];
    cloneprogressdata.splice(-1);
    setProgressDetails(cloneprogressdata);
  };

  const removeAlertMessage = (timeoutInms) => {
    if (timeoutInms) {
      setTimeout(() => {
        setResponseErrorMessage("");
      }, timeoutInms);
    } else {
      setResponseErrorMessage("");
    }
  };

  const showMessageToUser = (message, isSuccess) => {
    setResponseErrorMessage(message);
    setIsResponseOk(isSuccess);
    window.scrollTo(0, 0);
    removeAlertMessage(5000);
  };

  const SaveData = async () => {
    removeAlertMessage();
    console.log("Save Function call");
    if (progressDetails && progressDetails.length > 7) {
      showMessageToUser(
        "You can have maximum 7 items in progress options.",
        false
      );
      return;
    }

    let apiData = { Name: workoutUser };
    apiData.SectionList = progressDetails.map((item, index) => {
      return {
        Name: item.workoutName,
        Url: item.workoutUrl,
        OrderNumber: index,
      };
    });

    if (isEdit === true) {
      apiData.Id = progressDataId;
      dispatch(updateProgressbar(progressDataId, apiData));
    } else {
      dispatch(createProgressbar(apiData));
    }
  };

  return (
    <div className="container-fluid border mt-5 p-5">
      <div className="">
        <div className="">
          {responseErrorMessage.length > 0 && (
            <div
              className={
                isResponseOk ? "alert alert-success" : "alert alert-danger"
              }
            >
              {responseErrorMessage}
            </div>
          )}
        </div>
        <div className="row-cols-1 mt-5">
          <div className="col-6">
            <h3>{pageName}</h3>
          </div>
        </div>

        <div className="row row-cols-1 mt-5">
          <div className="col-3  ">
            <div className="col">Name</div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                name="name"
                value={workoutUser}
                onChange={(e) => setworkoutUser(e.target.value)}
                placeholder=""
              ></input>
            </div>
          </div>
        </div>

        <div className="form-group mt-5 mb-3 ml-3">
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={addContentBox}
          >
            Add Content
          </button>
        </div>

        {progressDetails.map((item, index) => {
          return (
            <div
              key={index}
              className="row border p-5 mt-5 justify-content-around"
            >
              <div className="col-6">
                <div className="col">Workout Text</div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="textName"
                    value={item.workoutName}
                    onChange={(e) =>
                      setProgressData("workoutName", e.target.value, index)
                    }
                    placeholder=""
                  ></input>
                </div>
              </div>

              <div className="col-3  ">
                <div className="col">Workout Url </div>
                <div className="col">
                  <input
                    type="urltext"
                    className="form-control"
                    name="WorkoutLink"
                    value={item.workoutUrl}
                    onChange={(e) =>
                      setProgressData("workoutUrl", e.target.value, index)
                    }
                    placeholder=""
                  ></input>
                </div>
              </div>

              <div className="col-1 d-flex justify-content-end mt-4 pt-2 mb-0 pr-4">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => removeContentBox(index)}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-trash"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="col mt-5">
        <div className="col-sm-12 d-flex justify-content-end pr-5">
          <button className="btn btn-primary" type="button" onClick={SaveData}>
            Save Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateProgressBar;
