import { Button } from "react-bootstrap";
import React, { useState } from "react";
import ModalEditor from "./ModalEditor";
import NewEditor from "./NewEditor";
import ViewEditor from "./ViewEditor";

const EditorLandingPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [secondData, setSecondData] = useState("");

  const getContentJson = (jsonData) => {
    console.log("Invoked in LP");
    console.log("JSON data", jsonData);
    setJsonData(jsonData);
    setModalShow(false);
  };

  const getContentJson2 = (jsonData) => {
    console.log("2 Invoked in LP");
    console.log("2 JSON data", jsonData);
    setSecondData(jsonData);
    setModalShow2(false);
  };

  return (
    <>
      <div>
        <Button onClick={() => setModalShow(true)}>Open Editor</Button>
        <NewEditor
          show={modalShow}
          onHide={setModalShow}
          onSave={getContentJson}
        />
        <div className="editor-view">
          {jsonData ? <ViewEditor dataToLoad={jsonData} /> : ""}
        </div>
      </div>
      <div>
        <Button onClick={() => setModalShow2(true)}>Open Editor</Button>
        <NewEditor
          show={modalShow2}
          onHide={setModalShow2}
          onSave={getContentJson2}
        />
        <div className="editor-view">
          {secondData ? <ViewEditor dataToLoad={secondData} /> : ""}
        </div>
      </div>
    </>
  );
};

export default EditorLandingPage;
