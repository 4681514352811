class Helper{
    convertToURL(input){
        if(input == null){
            return ''
        }
        
        input = input.trim().replace(/ /gi, '-');
        input = input.replace(/[^0-9a-z-]/gi, '');
        return input.toLowerCase();
    }

    getDate(){
        let today = new Date();
        today.setHours(today.getHours() + 15);
        let month = ('0' + (today.getMonth()+1)).slice(-2);
        let date =  ('0'+today.getDate()).slice(-2);
        return `${today.getFullYear()}-${month}-${date}`;
    }

}

export default new Helper();