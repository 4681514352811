export const EDITOR_LINK = "tbnc-default-link";
export const EDITOR_BTN_LINK = "tbnc-btn-link";
export const EDITOR_BTN_BORDER = "2px solid red";
export const EDITOR_BLOCK = "tbnc-default-block";
export const EDITOR_UNORDERED_LIST = "tbnc-default-ul";
export const EDITOR_TEAL = "#00a8a8";
export const EDITOR_FONT_WEIGHT = "bold";
export const EDITOR_FONT_SIZE = "18px";
export const EDITOR_UNDERLINE = "2px solid #00a8a8";
export const EDITOR_UL = "unordered-list-item";
export const EDITOR_TEAL_BOLD = "tbnc-teal-bold";
export const EDITOR_INLINE_UNDERLINE = "tbnc-inline-underline";
export const EDITOR_INLINE_ITALIC = "tbnc-inline-italic";
export const EDITOR_INLINE_BOLD = "tbnc-inline-bold";
