import MarketDataService from "../../../components/marketData/MarketDataService";
import {
  CREATE_MARKETDATA,
  RETRIEVE_MARKETDATA,
  RETRIEVE_BY_ID_MARKETDATA,
  UPDATE_MARKETDATA,
  DELETE_MARKETDATA,
} from "./MarketdataActionType";

// =========================================================================

export const createMarketdata = (apiData) => async (dispach, getAction) => {
  try {
    console.log("Action=> Create Market Data Request");
    const { data, success } = await MarketDataService.create(apiData);
    dispach({ type: CREATE_MARKETDATA, payload: data });
    console.log("Action=> Create Market Data Response");
  } catch (error) {
    console.log("Action=> Create Market Data Error Response", error);
  }
};

// -------------------------------------------------------------------------

export const retrieveMarketdata =
  (publishDate) => async (dispach, getAction) => {
    try {
      console.log("Action=> Retrieve Market Data Request", publishDate);
      const { data, success } = await MarketDataService.retreive(publishDate);
      console.log("Action=> Retrieve Market Data Response", data);
      dispach({ type: RETRIEVE_MARKETDATA, payload: data });
    } catch (error) {
      console.log("Action=> Retrieve Market Data Error Response", error);
    }
  };

// -------------------------------------------------------------------------

export const retrieveByIdMarketdata = (id) => async (dispach, getAction) => {
  try {
    console.log("Action => API RetrieveById Request to Server");
    const { data, success } = await MarketDataService.retrivemarketDataById(id);
    console.log("Action =>Market Data GetById API Response", data);
    dispach({ type: RETRIEVE_BY_ID_MARKETDATA, payload: data });
  } catch (error) {
    console.log("Action API RetrieveById Response - Error", error);
  }
};

// -------------------------------------------------------------------------

export const updateMarketdata =
  (progressDataId, apiData, id) => async (dispach, getAction) => {
    try {
      console.log("Action => API Update Request to Server", progressDataId);
      const { data, success } = await MarketDataService.edit(
        progressDataId,
        apiData
      );
      console.log("Action => API Update/Edit Response", progressDataId);
      dispach({ type: UPDATE_MARKETDATA, payload: id });
    } catch (error) {
      console.log("Action API Update/Edit Response - Error", error);
    }
  };

// -------------------------------------------------------------------------

export const deleteMarketdata = (id) => async (dispach, getAction) => {
  try {
    console.log("Action => API Delete Request to Server");
    const { data, success } = await MarketDataService.delete(id);
    console.log("Action => API Delete response", success);
    dispach({ type: DELETE_MARKETDATA, payload: { id } });
  } catch (error) {
    console.log("Action API Delete Response - Error", error);
  }
};

// -------------------------------------------------------------------------
